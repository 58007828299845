@import "mixins";

app-user-reset-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: #f6f6f6;

  .panel#user-reset-password {
    max-width: 366px;
    flex: 1;
    max-height: 609px;
    margin-top: 5.8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    app-form-control
      mat-form-field
      .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex,
    .mat-mdc-form-field-flex {
      background-color: #ffffff;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
    mat-form-field {
      .mat-mdc-form-field-infix {
        padding: unset !important;
        border: unset;
        height: 20px;
      }
      label {
        padding-top: 5px;
      }
      .mat-mdc-form-field-flex {
        padding: 21px 12px 21px 12px;
        border: 1px solid #aeaeae;
      }
      input {
        margin-top: 8px !important;
      }
      .mat-select-trigger {
        margin-top: 8px !important;
      }

      &.mat-form-field-disabled {
        background-color: #ffffff;
        .mat-mdc-text-field-wrapper {
          padding-bottom: unset;
        }
        input,
        .mat-select-value-text {
          color: #333333 !important;
          font-weight: bold;
        }
        .mat-select-arrow {
          color: #ffffff !important;
        }
        .mat-mdc-form-field-flex {
          background-color: #ffffff !important;
        }
        label {
          color: #666666 !important;
        }
      }
    }
    button#buttonChangePassword {
      box-shadow: none;
      span.mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      height: 56px;
    }

    mat-card {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 360px) {
    .panel#user-reset-password {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
      #first-access-button-box {
        margin: auto;
      }
    }
    .panel#user-reset-password {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#user-reset-password {
      // margin-bottom: auto;
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
    .panel#user-reset-password {
      // margin-bottom: auto;
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
