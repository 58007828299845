app-fiserv-external-user-edit {
  .mat-mdc-form-field.disabled-login.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper {
    background-color: #0000001a;
  }
  // .mat-checkbox-frame {
  //   border-radius: 4px !important;
  //   border-color: #333333 !important;
  // }
  // .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  //   background-color: transparent !important;
  //   .mat-checkbox-checkmark-path {
  //     stroke: #333333 !important;
  //   }
  // }

  // .mat-radio-outer-circle {
  //   border-color: #333333 !important;
  // }
  // .mat-radio-inner-circle {
  //   background-color: #333333 !important;
  // }

  // .form-invite {
  //   .mat-form-field.mat-form-field-appearance-fill
  //     .mat-mdc-text-field-wrapper
  //     .mat-mdc-form-field-flex {
  //     background-color: var(
  //       --app-form-control-input-mat-form-field-background-color,
  //       white
  //     );
  //     border: var(--app-form-control-input-mat-form-field-flex-border);
  //     color: #eff5f6;
  //     box-sizing: var(--app-form-control-input-mat-form-field-flex-box-sizing);
  //     border-radius: 2px;
  //     width: var(--app-form-control-input-mat-form-field-flex-width);
  //     flex: 1 1;
  //     display: flex;
  //     padding: 12px 16px 8px;
  //     height: 42px;
  //     align-items: center;
  //   }

  //   .disabled-login {
  //     height: auto;
  //     // max-height: 62px;
  //     &.mat-form-field.mat-form-field-appearance-fill
  //       .mat-mdc-text-field-wrapper {
  //       padding: 0;
  //       background-color: #0000001a;
  //     }
  //   }

  //   app-form-control {
  //     // min-width: 288px;
  //     // &:first-child {
  //     //     grid-column: span 6;
  //     // }
  //     // &:nth-child(2) {
  //     //     grid-column: span 4;
  //     // }
  //     // &:nth-child(3) {
  //     //     grid-column: span 4;
  //     // }
  //     // &:nth-child(4) {
  //     //     grid-column: span 5;
  //     // }
  //     // &:nth-child(5) {
  //     //     grid-column: span 4;
  //     // }
  //     &[id="email"]
  //       mat-form-field.mat-form-field-disabled.mat-form-field-appearance-fill
  //       .mat-mdc-text-field-wrapper {
  //       padding: 0;
  //       .mat-mdc-form-field-flex {
  //         background-color: #dbdbdb !important;
  //         input,
  //         label {
  //           color: #aeaeae !important;
  //         }
  //         mat-label {
  //           color: #aeaeae !important;
  //         }
  //       }
  //     }

  //     &[id="cpf"] {
  //       mat-form-field.mat-form-field-appearance-fill
  //         .mat-mdc-text-field-wrapper
  //         .mat-mdc-form-field-flex {
  //         background-color: #dbdbdb !important;
  //         input,
  //         label {
  //           color: #aeaeae !important;
  //         }
  //         mat-label {
  //           color: #aeaeae !important;
  //         }
  //       }
  //     }
  //   }

  //   &.filledInvitation {
  //     background-color: #ffffff;
  //     padding: 1.34375em;

  //     .mat-form-field-appearance-fill.mat-form-field-disabled
  //       .mat-mdc-form-field-flex {
  //       background-color: var(
  //         --app-form-control-input-mat-form-field-background-color,
  //         white
  //       );
  //       border: var(--app-form-control-input-mat-form-field-flex-border);
  //       color: #eff5f6;
  //       box-sizing: var(
  //         --app-form-control-input-mat-form-field-flex-box-sizing
  //       );
  //       border-radius: 2px;
  //       width: var(--app-form-control-input-mat-form-field-flex-width);
  //       width: -webkit-fill-available;
  //       width: -moz-available;
  //       padding: 12px 16px 8px;
  //       height: 42px;
  //       align-items: center;
  //       padding: 12px 16px 8px;
  //       height: calc(62px - 20px);
  //       align-items: center;
  //       .mat-mdc-form-field-infix {
  //         padding: 0.25em 0 0.25em 0;
  //         input {
  //           font-size: 14px;
  //           line-height: 16px;
  //           letter-spacing: -0.02em;
  //           color: #333333 !important;
  //           font-weight: 700;
  //           font-style: normal;
  //         }
  //         .mat-form-field-label-wrapper {
  //           label {
  //             color: #48586c;
  //             font-size: 12px;
  //             line-height: 14px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .footer-container .footer-button button.mat-raised-button {
    box-shadow: none;
    &.continue-button span.mat-button-wrapper {
      text-align: center;
    }
  }
  @media (max-width: 582px) {
    .subtitle-info {
      display: none;
    }
  }
  @media (max-width: 556px) {
    div.page {
      main.content {
        div.form-container {
          app-form.ng-invalid form.ng-invalid div.form-invite {
            app-form-control {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: 557px) and (max-width: 582px) {
    div.page {
      main.content {
        padding-bottom: 44px;
        div.form-container {
          // app-form.ng-invalid form.ng-invalid div.form-invite {
          //     grid-template-columns: 1fr !important;
          //     app-form-control {
          //         grid-column: span 1;
          //     }
          // }
          // app-form.ng-valid form.ng-valid div.form-invite {
          //     app-form-control {
          //         grid-column: span 7;
          //     }
          // }
        }
      }
    }
  }
  @media (min-width: 583px) and (max-width: 770px) {
    div.page {
      main.content {
        padding-bottom: 44px;
        div.form-container {
          // app-form.ng-invalid form.ng-invalid div.form-invite {
          //     grid-template-columns: 1fr 1fr !important;
          //     app-form-control {
          //         grid-column: span 1;
          //     }
          // }
          // app-form.ng-valid form.ng-valid div.form-invite {
          //     app-form-control {
          //         grid-column: span 7;
          //     }
          // }
        }
      }
    }
  }

  // .disabled-login {
  //   height: auto;
  //   // max-height: 62px;
  //   &.mat-form-field.mat-form-field-appearance-fill
  //     .mat-mdc-text-field-wrapper {
  //     padding: 0;
  //     .mat-mdc-form-field-flex {
  //       background-color: #0000001a !important;
  //     }
  //   }

  //   @media (max-width: 1064px) {
  //     &.mat-form-field.mat-form-field-appearance-fill
  //       .mat-mdc-text-field-wrapper {
  //       padding-bottom: 1.34375em;
  //     }
  //   }
  // }
}
