app-external-user-document {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: var(--app-external-user-document-bg-color);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .panel {
    max-width: 366px;
    flex: 1;
    max-height: 609px;
    margin-top: 5.8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .form-container {
      margin: 32px auto 0px auto;
      width: 286px;

      mat-form-field.mat-form-field-appearance-fill {
        width: 100%;
        .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
          text-align: end;
        }
        .mat-mdc-text-field-wrapper {
          border: 1px solid #cdd3cd;
          border-radius: 2px;
          background-color: transparent;

          .mat-mdc-form-field-focus-overlay {
            opacity: 0;
          }

          .mdc-line-ripple {
            display: none;
          }
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              input {
                font-size: 14px;
                color: #333333;
                font-weight: 700;
              }
              .mdc-floating-label {
                color: #48586c;
              }
            }
          }

          &[disabled="true"] {
            .mat-mdc-form-field-flex {
              border: unset;
              label {
                color: #aeaeae;
              }
              .mat-form-field-suffix {
                height: 100%;
                margin: auto;
              }
            }
          }
        }
      }

      .box-button-actions {
        margin-top: calc(44px - 1.34375em);
        display: flex;

        button {
          flex: 1;
          height: 44px;
          border-radius: 2px;
          flex-direction: row-reverse;
          justify-content: space-between;
          box-shadow: none;
          font-weight: bold;
          color: #ffffff;
          font-size: 16px;
          letter-spacing: 0.02rem;
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .panel#external-user-insert-document {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
      #first-access-button-box {
        margin: auto;
      }
    }
    .panel#insert-code.panel {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#external-user-insert-document {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
    .panel#insert-code.panel {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
