app-institutional-user-edit-internal {
  --mat-form-field-state-layer-color: white;
  .form-container.form-container-filled {
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .slide-container .mat-mdc-slide-toggle {
    .mdc-label {
      font-weight: bold;
    }

    &.status-container {
      &.mat-mdc-slide-toggle-checked {
        .mdc-label {
          color: #219653;
          font-weight: bold;
        }
      }
    }
    &.lock-container {
      &:not(.mat-mdc-slide-toggle-checked) {
        .mdc-label {
          color: #fe5a59;
          font-weight: bold;
        }
      }

      .mdc-form-field
        .mdc-switch:enabled:hover:not(:focus):not(:active)
        .mdc-switch__track::before {
        background: #fe5a59;
      }
      .mdc-form-field
        .mdc-switch:enabled:not(:active)
        .mdc-switch__track::before {
        background: #fe5a59;
      }
    }
  }
  .mat-mdc-form-field.mat-form-field-appearance-fill {
    width: 100%;

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        .mdc-floating-label {
          color: #48586c;
        }
        .mat-mdc-input-element {
          font-size: 14px;
          color: #333333;
          font-weight: 700;
        }
        .mat-mdc-select .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
        }
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
    .mat-mdc-form-field-hint-spacer {
      display: none;
    }
    .mat-mdc-form-field-hint {
      display: flex;
      .mat-icon {
        width: -webkit-fill-available;
        max-width: fit-content;
        font-size: 14px;
        margin: 2px;
      }
    }
  }
  .form-invite:not(.filledInvitation)
    .mat-mdc-form-field.mat-form-field-disabled.mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
      background-color: rgba(102, 102, 102, 0.3);
      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        .mdc-floating-label {
          color: #666666;
        }
        .mat-mdc-input-element {
          color: #666666;
        }
        .mat-mdc-select .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            color: #666666;
          }
        }
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  div.footer-container {
    div.footer-button {
      button.continue-button {
        span.mat-button-wrapper {
          text-align: center;
        }
      }
    }
  }

  mat-slide-toggle.mat-slide-toggle.mat-checked {
    label.mat-slide-toggle-label {
      height: 40px;

      span.mat-slide-toggle-bar {
        width: 48px;
        height: 24px;
        border-radius: 20px;
        background-color: #219653;
        .mat-slide-toggle-thumb-container {
          width: 16px;
          height: 16px;
          top: 4px;
          transform: translate3d(27px, 0, 0);
          .mat-slide-toggle-thumb {
            width: 16px;
            height: 16px;
            background-color: #ffffff;
          }
        }
      }
    }

    &.mat-disabled {
      label {
        color: #aeaeae;
        .mat-slide-toggle-bar {
          background-color: #aeaeae;
        }
      }
    }
  }
  mat-slide-toggle:not(.mat-checked) {
    label.mat-slide-toggle-label {
      height: 40px;

      .mat-slide-toggle-bar {
        width: 48px;
        height: 24px;
        border-radius: 20px;
        .mat-slide-toggle-thumb-container {
          width: 16px;
          height: 16px;
          top: 4px;
          left: 4px;
          .mat-slide-toggle-thumb {
            width: 16px;
            height: 16px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .panel {
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: #333333;
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: #333333;
    }
    .slide-service-contract {
      mat-slide-toggle.mat-checked {
        label.mat-slide-toggle-label {
          height: 40px;

          .mat-slide-toggle-bar {
            width: 48px;
            height: 24px;
            border-radius: 20px;
            background-color: #333333 !important;
            .mat-slide-toggle-thumb-container {
              width: 16px;
              height: 16px;
              top: 4px;
              transform: translate3d(27px, 0, 0);
              .mat-slide-toggle-thumb {
                width: 16px;
                height: 16px;
                background-color: #ffffff;
              }
            }
          }
        }
      }
      mat-slide-toggle:not(.mat-checked) {
        label.mat-slide-toggle-label {
          height: 40px;

          .mat-slide-toggle-bar {
            width: 48px;
            height: 24px;
            border-radius: 20px;
            background-color: #ffffff;
            border: 2px solid #333333;
            .mat-slide-toggle-thumb-container {
              width: 16px;
              height: 16px;
              top: 4px;
              left: 4px;
              .mat-slide-toggle-thumb {
                width: 16px;
                height: 16px;
                background-color: #333333;
              }
            }
          }
        }
      }
    }
  }
}
