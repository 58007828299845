@import "mixins";

mat-stepper.default-model {
    .mat-horizontal-stepper-header-container {
        // max-width: 192px;
        height: 24px;
        margin: 40px auto 16px auto;
        @include no-select;
        pointer-events: none;
        & > * {
            @include no-select;
        }

        .mat-stepper-horizontal-line {
            margin: unset;
        }

        mat-step-header.mat-step-header {
            background-color: transparent;
            max-width: 24px;
            .mat-step-icon-state-done {
                color: var(--color-white);
                background-color: rgba(0, 0, 0, 0.54);
            }
        }
        .mat-stepper-horizontal-line {
            border-width: 1px;
            min-width: unset;
            width: 27px;
            margin-left: 18px;
            // margin: initial !important;
            margin-right: 18px;
        }
        .mat-horizontal-stepper-header {
            padding: unset;
        }
    }

    &[aria-label="default-model-index-0"] {
        .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container {
            mat-step-header.mat-step-header:first-child {   
                .mat-step-icon {
                    color: #ffffff;
                }
            }
            mat-step-header.mat-step-header:not(:first-child) {
                height: fit-content;
                border-radius: 24px;
                border: 2px solid #dbdbdb;
                .mat-step-icon {
                    background-color: #ffffff;
                    color: #666666;
                    font-weight: bold;
                }
            }
        }
    }
    &[aria-label="default-model-index-1"] {
        .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container {
            mat-step-header.mat-step-header:nth-child(-n + 3) {   
                .mat-step-icon {
                    color: #ffffff;
                }
            }
            mat-step-header.mat-step-header:not(:nth-child(-n + 3)) {
                height: fit-content;
                border-radius: 24px;
                border: 2px solid #dbdbdb;
                .mat-step-icon {
                    background-color: #ffffff;
                    color: #666666;
                    font-weight: bold;
                }
            }
        }
    }
    &[aria-label="default-model-index-2"] {
        .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container {
            mat-step-header.mat-step-header:nth-child(-n + 5) {   
                .mat-step-icon {
                    color: #ffffff;
                }
            }
            mat-step-header.mat-step-header:not(:nth-child(-n + 5)) {
                height: fit-content;
                border-radius: 24px;
                border: 2px solid #dbdbdb;
                .mat-step-icon {
                    background-color: #ffffff;
                    color: #666666;
                    font-weight: bold;
                }
            }
        }
    }
    &[aria-label="default-model-index-3"] {
        .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container {
            mat-step-header.mat-step-header {
              
                .mat-step-icon {
                    color: #ffffff;
                  
                }
            }
        }
    }
}

// @media (max-width: 700px) {
//     mat-stepper,
//     .mat-stepper-vertical {
//         background-color: unset !important;
//     }
//     .mat-horizontal-stepper-header-container {
//         display: none;
//     }
// }
