@import "mixins";

app-external-user-done {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: var(--external-user-done-background-color);

  div#external-user-done
    mat-stepper
    div.mat-horizontal-stepper-header-container {
    height: 24px;
    margin: 40px auto 16px auto;
    @include no-select;
    pointer-events: none;
    & > * {
      @include no-select;
    }
    .mat-step-header {
      max-width: 24px;
      padding: unset;
    }

    .mat-step-header .mat-step-icon-selected {
      color: var(--color-white);
    }

    mat-step-header[id="cdk-step-label-0-0"] {
      .mat-step-icon-state-number {
        .mat-step-icon-state-number {
          background-color: var(
            --external-user-done-mat-step-icon-state-number-background-color-select-true
          );
          color: var(
            --external-user-done-mat-step-icon-state-number-color-select-true
          );
          border: var(
              --external-user-done-mat-step-icon-state-number-border-round
            )
            var(
              --external-user-done-mat-step-icon-state-number-border-line-style
            )
            var(--external-user-done-mat-step-icon-state-number-border-color);
          box-sizing: border-box;
        }
      }
      .mat-horizontal-stepper-header {
        padding: unset;
      }

      @media screen and (max-width: 360px) {
        .panel#external-user-done {
          max-width: 100vw;
          min-width: 288px;
          margin-bottom: unset;
          margin-top: unset;
          background-color: unset;
        }
      }

      @media screen and (max-height: 700px) {
        .panel#external-user-done {
          margin-bottom: 5.8vh;
          min-height: 90vh;
          height: fit-content;
        }
      }
    }
  }

  #box-button-actions {
    margin-top: calc(44px - 1.34375em);

    button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      height: 56px;
      border-radius: var(
        --external-user-done-mat-step-icon-state-number-border-round
      );
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-white);
      .mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      box-shadow: none !important;
    }
  }
}
