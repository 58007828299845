app-user-forgot-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: #f6f6f6;

  --app-form-control-input-mat-form-field-flex-width: 100%;

  app-form form #box-button-actions button.mdc-button,
  #form-container #box-button-actions button {
    --mdc-protected-button-label-text-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  app-form-control mat-form-field .mat-mdc-text-field-wrapper {
    width: 286px;
  }

  .mat-horizontal-content-container {
    padding: unset;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    background-clip: text;
  }
  app-form-change-password form mat-card {
    box-shadow: none;
  }

  #form-container {
    #box-button-actions {
      button {
        box-shadow: none;
        .mat-button-wrapper {
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  @media (min-width: 700px) {
    #form-token-container app-form-token-group p.message-warn {
      margin: 53px 53px auto 63px;
    }
  }
}
