app-external-user-list {
  .toast-importation {
    display: flex;
    justify-content: space-between;
    height: 24px;
    background-color: #219653;
    padding: 16px 24px;
    margin: 32px 0px;
    &.warning {
      background-color: red;
    }
    .information {
      display: flex;
      span.text {
        margin: auto auto auto 16px;

        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }
    .close-box {
      button {
        height: 24px;
        width: 24px;
        display: flex;
        .mat-button-wrapper {
          display: flex;
          mat-icon svg line {
            stroke: #fff;
          }
        }
      }
    }
  }

  main.content {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .table-groups {
      .filter-container {
        .mat-form-field-appearance-fill {
          min-width: 386px;
          .mat-mdc-form-field-flex {
            background-color: #fff;
          }
        }

        button.create-group-button {
          color: white;
          min-width: 198px;
          height: 44px;
          border-radius: 2px;
          font-weight: bold;
          margin-left: auto;
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      table.mat-mdc-table[id="22"] {
        width: 100%;
        box-shadow: none;

        th {
          border-bottom: none;
          &:first-of-type {
            padding-left: 32px;
          }
          &:last-of-type {
            padding-right: 32px;
          }
        }

        tbody {
          background: white;
          tr {
            background: white;
            td {
              border-bottom: 3px solid #f6f6f6;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-right: 40px;
              // cursor: pointer;
              // &:not(:first-of-type) {
              //     cursor: pointer;
              // }
              // &:active {
              //     cursor: -webkit-grab;
              //     cursor: grab;
              // }
              &:first-of-type {
                padding-left: 32px;
              }
              &:last-of-type {
                padding-right: 32px;
              }
            }
          }
        }

        .column-title {
          font-size: 14px;
          color: #333333;
          font-weight: 700;
          &.status {
            text-align: center;
          }
        }
        .cell-information {
          font-size: 14px;
          color: #333333;
        }
      }
    }

    .mat-tab-header
      .mat-tab-label-container
      .mat-tab-list
      .mat-tab-labels
      .mat-tab-label {
      opacity: 1;

      &.mat-tab-label-active {
        .mat-tab-label-content {
          color: var(--primary-color);
        }
      }
      .mat-tab-label-content {
        color: #666666;
        font-weight: bold;
      }

      &.tab-button-invite {
        padding: 0;
        margin-left: auto;

        a {
          width: 172px;
          height: 44px;
          padding: 10px 16px 10px 16px;
          border-radius: 2px;
          display: flex;
          color: #ffffff;
          box-shadow: none;
          font-weight: 700;
        }

        + .tab-button-new-importation {
          margin-left: 24px;
        }
      }
      &.tab-button-new-importation {
        padding: 0;
        margin-left: auto;

        button {
          width: 199px;
          height: 44px;
          padding: 10px 18px 10px 16px;
          border-radius: 2px;
          display: flex;
          box-shadow: none;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          font-weight: 700;
          span.mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            flex: 1;
            align-items: center;
            height: -webkit-fill-available;
            mat-icon {
              width: 18px;
              height: 22px;
              svg path {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }

    .nav-buttons-actions {
      button {
        span.mat-button-wrapper {
          display: flex;
          justify-content: space-between;
          flex: 1;
          align-items: center;
          height: -webkit-fill-available;
          mat-icon {
            width: 18px;
            height: 22px;
            svg path {
              fill: var(--primary-color);
            }
          }
        }
      }
    }

    input {
      border: 1px solid white !important;
    }

    mat-form-field#userTypeSelect {
      flex: 1 1;
      max-width: 282px;
    }
    .container-other-filters .mat-mdc-form-field-flex {
      background-color: white;
    }

    .title {
      div.title-container {
        a.add-user {
          span.mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }

    nav .mat-tab-link {
      font-weight: bold;
      color: #aeaeae;
      &.mat-tab-label-active {
        color: var(--app-external-user-list-nav-mat-tab-label-active-color);
      }
    }
    .menu-filter {
      button {
        &.activated-filter {
          color: var(
            --app-external-user-list-menu-filter-button-activated-filter-color
          );
          border: var(
            --app-external-user-list-menu-filter-button-activated-filter-border
          );
        }
      }
    }

    div.menu-filter
      mat-form-field.dropdown-mob-filter
      .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex {
      background-color: white;
    }
  }

  a.add-user-mob {
    display: initial;
    width: 100%;
    height: 44px;
    color: white !important;
    align-items: center;

    span.mat-button-wrapper {
      display: flex;
      /* justify-content: unset; */
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  a.add-user {
    span.mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  @media (max-width: 556px) {
    nav .mat-tab-links a {
      flex: 1;
      font-size: 16px !important;
    }

    button.mob-importation-button {
      span.mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1;
        align-items: center;
        height: 24px;
        mat-icon {
          width: 18px;
          height: 22px;
          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }

    .close-box {
      display: flex;
      font-size: 24px;
      justify-content: space-between;
      margin-top: 17.7px;
      margin-bottom: 24px;
      align-items: center;
      mat-icon {
        color: #666666;
      }
    }
    mat-form-field.select-servicecontract-mobile.mat-form-field-appearance-fill {
      &.activated-filter {
        .mat-mdc-form-field-flex {
          border-radius: 2px;
          background-color: #ff6600;
          .mat-select-value,
          .mat-select-arrow {
            color: #ffffff !important;
          }
        }
      }
      .mat-mdc-form-field-flex {
        width: auto;
        height: 33px;
        display: flex;
        padding-top: 0px;
        align-items: center;
        background-color: #ffffff;
        .mat-mdc-form-field-infix {
          width: fit-content;
          mat-select {
            width: fit-content;
            .mat-select-trigger {
              .mat-select-value {
                max-width: fit-content;
                display: flex;
                margin-right: 10px;
                color: #666666;
              }
              .mat-select-arrow-wrapper {
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
    .mobile-filtre-options {
      mat-form-field {
        width: 100%;
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background-color: #ffffff;
            .mat-mdc-form-field-infix {
              margin-top: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }

      .search-button-container {
        margin-top: calc(81px - 1.34375em);
        button.mat-raised-button.mat-primary {
          width: 100%;
          height: 56px;
          font-size: 16px;
          padding: 0.75em 0.75em;
          box-shadow: none !important;
          &:not([disabled="true"]) {
            color: white;
          }
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    a.add-user-mob {
      height: 56px;
      font-size: 16px;
      span.mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
