app-fiserv-data-card-services-contracts {

    .no-item {
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        flex-direction: column;
        mat-icon {
            width: 288px;
            height: 176px;
        }
        span {
            color: #BDBDBD;
            font-weight: bold;
            text-align: center;
            max-width: 210px;
            margin-top: 9px;
        }
    }

    button.submit-service-contract {
        .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            flex: 1;
            align-items: center;
        
        }
    }

    @media (max-width: 634px) {
        .data-service-contract {
            .details-contract-container {
                .container-card {
                   
                    app-card-channel {
                        display: flex;
                        flex: 1;
                        mat-card {
                            flex: 1;
                            max-width: 256px;
                            align-items: center;
                            height: 213px;
                        }
                    }
                }
            }
        }
    }
}
