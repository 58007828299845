@import "mixins";

app-user-confirm-invitation {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: var(--app-user-confirm-invitation-bg-color);

  .panel {
    max-width: 366px;
    flex: 1;
    max-height: 609px;
    margin-top: 5.8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .token-input-container {
      .mat-form-field {
        .mat-mdc-text-field-wrapper {
          padding: 0;
          .mat-mdc-form-field-flex {
            height: 56px;
            padding: 0;
            background: #ffffff;
            border: 1px solid #aeaeae;
            box-sizing: border-box;
            border-radius: 2px;
            text-align: center;
            align-items: center;
            .mat-mdc-form-field-infix {
              padding: 0;
              margin: 0;
              border: none;

              input[readonly="true"] {
                color: rgba(0, 0, 0, 0.38);
              }
            }
          }
        }
      }
    }

    #form-container {
      margin: 52px auto 0px auto;
      width: 286px;
      form {
        .readonly {
          .mat-input-element {
            box-shadow: 0 0 0 30px #dbdbdb inset;
          }

          .mat-mdc-form-field-flex {
            background-color: #dbdbdb;
          }

          input {
            color: #aeaeae;
          }
          input:-webkit-autofill {
            -webkit-text-fill-color: #aeaeae;
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .panel#external-user-confirm-invitation {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#external-user-confirm-invitation {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
