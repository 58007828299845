@use "@angular/material" as mat;
@import "mixins";
@import "../abstracts/_palettes.scss";

html[theme="scotia"] {
  // Colors
  --primary-color: var(--color-light-grayish-red);

  // External Login Page
  app-external-login {
    background-color: #f5f6fc;
    --external-login-input-webkit-autofill: rgba(0, 0, 0, 0.04);
    --external-login-welcome-container-h2-color: var(--color-davy-grey);
    --external-login-forget-password-link-color: var(--color-nice-blue);
    --external-login-buttons-password-email-reflect-disabled-false-bg-color: var(
      --color-light-grayish-red
    );
    --external-login-buttons-password-email-reflect-disabled-false-color: var(
      --color-white
    );
    --external-login-buttons-password-email-reflect-disabled-true-bg-color: var(
      --color-silver-sand
    );
    --external-login-buttons-password-email-reflect-disabled-true-color: var(
      --color-white
    );
    --external-login-first-access-button-bg-color: var(--color-white);

    --app-form-control-mat-form-field-flex-border: "transparent";
    --app-form-control-mat-form-field-flex-box-sizing: "none";
    --app-form-control-input-mat-form-field-background-color: var(
      --color-white
    );
    --app-form-control-input-mat-form-field-flex-width: calc(285px - 32px);
    --app-form-control-input-mat-form-field-flex-border: 1px solid
      var(--color-light-gray);

    #first-access-button {
      --external-login-first-access-button-border: none;
      --external-login-first-access-button-bg-color: var(--color-silver-sand);
      --external-login-first-access-button-color: var(--color-nice-blue);
    }
    app-form-control#email mat-form-field.readonly {
      --app-form-control-input-mat-form-field-flex-border: none;
    }

    mat-icon[data-mat-icon-name="eyes-closed"] {
      path {
        fill: var(--color-light-gray);
      }
    }
  }

  // External User Document
  --app-external-user-document-btn-disabled-false-bg-color: var(
    --color-blaze-orange
  );
  --app-external-user-document-btn-disabled-true-bg-color: var(
    --color-gainsboro
  );
  --app-external-user-document-bg-color: var(--color-wild-sand);
  --app-external-user-document-panel: var(--color-white);
  --app-external-user-document-mat-form-field-flex: var(--color-white);

  // External User Confirm Invitation
  --app-user-confirm-invitation-bg-color: var(--color-wild-sand);
  --app-user-confirm-invitation-mat-step-header-selected-false-bg-color: var(
    --color-white
  );
  --app-user-confirm-invitation-mat-step-header-selected-false-color: var(
    --color-gainsboro
  );
  --app-user-confirm-invitation-mat-step-header-selected-false-border-bg-color: var(
    --color-gainsboro
  );
  --app-user-confirm-invitation-mat-step-header-selected-false-box-sizing: border-box;
  --app-user-confirm-invitation-mat-step-header-selected-false-mat-step-icon-state-number-bg-color: var(
    --color-white
  );
  --app-user-confirm-invitation-mat-step-header-selected-true: var(
    --color-white
  );
  --app-user-confirm-invitation-mat-step-header-selected-true-mat-stepper-horizontal-line-border-top-line: var(
    --color-light-grayish-red
  );

  // External User Password
  --app-user-password-bg-color: var(--color-wild-sand);
  --app-user-password-panel-bg-color: var(--color-white);
  --app-user-password-mat-form-field: var(--color-white);
  --app-user-password-mat-form-field-flex: var(--color-silver-chalice);
  --app-user-password-mat-form-field-disabled: var(--color-white);
  --app-user-password-mat-select-value-text: var(--color-graphite-gray);
  --app-user-password-mat-select-arrow: var(--color-white);
  --app-user-password-mat-form-field-flex-label: var(--color-white);
  --app-user-password-mat-step-header-bg-color-selected-false: var(
    --color-white
  );
  --app-user-password-mat-step-header-color-selected-false: var(
    --color-gainsboro
  );
  --app-user-password-mat-step-header-border-selected-false: var(
    --color-gainsboro
  );
  --app-user-password-mat-step-icon-state-number: var(
    --color-light-grayish-red
  );
  --app-user-password-mat-step-header-color-selected-true: var(--color-white);
  --app-user-password-mat-step-header-bg-color-selected-true: var(
    --color-light-grayish-red
  );

  // User Forget Password Page
  app-user-forgot-password {
    --user-forgot-password-form-container-button-continue-button-disabled-false-bg-color: var(
      --color-light-grayish-red
    );
    --user-forgot-password-form-container-button-continue-button-disabled-false-color: var(
      --color-white
    );
    --user-forgot-password-form-container-button-continue-button-disabled-true-bg-color: var(
      --color-gainsboro
    );
    --app-form-control-input-mat-form-field-flex-border: 1px solid
      var(--color-silver-oil);
    --app-form-control-mat-form-field-flex-box-sizing: border-box;
    --app-form-control-input-mat-form-field-flex-width: calc(285px - 32px);
    --app-user-forgot-password-mat-step-header-stepper-horizontal-line: var(
      --color-light-grayish-red
    );
  }

  // Language-selector Component
  --language-selector-select-arrow-color: var(--color-midnight-blue);
  --language-selector-options-bg-color: var(--color-white);
  --language-selector-span-text-info-selected-language-color: var(
    --color-davy-grey
  );
  --language-selector-select-filter: drop-shadow(
    0px 0px 15px rgba(0, 0, 0, 0.32)
  );
  --language-selector-select-box-shadow: none;

  // External User List Page

  --app-external-user-list-nav-mat-tab-label-active-color: var(--primary-color);
  --app-external-user-list-menu-filter-button-activated-filter-color: var(
    --primary-color
  );
  --app-external-user-list-menu-filter-button-activated-filter-border: 1px solid
    var(--primary-color);

  app-external-user-name {
    --app-external-user-name-button-disabled-false: var(
      --color-light-grayish-red
    );
    --app-external-user-name-mat-stepper-horizontal-line: var(
      --color-light-grayish-red
    );
  }

  // External User Done

  --external-user-done-background-color: var(--color-wild-sand);
  --external-user-done-mat-step-icon-state-number-background-color-select-false: var(
    --color-charcoal-gray
  );
  --external-user-done-mat-step-icon-state-number-background-color-select-true: var(
    --color-thunderbird
  );
  --external-user-done-mat-step-icon-state-number-border-color-select-false: var(
    --color-charcoal-gray
  );

  --external-user-done-mat-step-icon-state-number-font-color: var(
    --color-white
  );
  --external-user-done-mat-step-icon-state-number-border-round: 2px;
  --external-user-done-mat-step-icon-state-number-border-line-style: solid;
  --external-user-done-mat-step-icon-state-number-border-color: var(
    --color-gainsboro
  );
  --external-user-done-mat-stepper-horizontal-line: var(
    --color-light-grayish-red
  );

  --external-user-done-webkit-autofill: rgba(0, 0, 0, 0.04);
  --external-user-done-container-h2-color: var(--color-white);

  app-external-user-list {
    .mat-mdc-card-image {
      margin: 0px;
    }
  }
}
