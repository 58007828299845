.overlay-container {
    .toast-container#toast-container {
        .ngx-toastr {
            border-radius: 2px;
            &.form-token-group-toast-error,
            &.form-token-group-toast,
            &.form-token-group-toast-error-user-blocked {
                margin-top: calc(5.8vh + 40px);
            }
          }
          
    }
    
}

