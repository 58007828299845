app-external-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: var(--background-color, #f6f6f6);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .mat-form-field-underline {
    display: none;
  }

  #box-button-actions {
    button {
      .mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  button#continue-button {
    box-shadow: none;
  }
  #form-container {
    margin: 32px auto 0px auto;
    width: 286px;
    form {
      .readonly {
        .mat-mdc-form-field-flex {
          background-color: #dbdbdb;
        }

        input {
          color: #aeaeae !important;
        }
        input:-webkit-autofill {
          -webkit-text-fill-color: #aeaeae;
        }
      }
    }
  }

  #first-access-button-box
    #first-access-button:hover:not(.mat-mdc-button-disabled)
    .mat-button-focus-overlay {
    opacity: 0;
  }

  @media (max-width: 576px) {
    background-color: #ffffff;
  }
}

.external-login-toast-error {
  margin-top: calc(5.8vh + 40px);
}
