@import "mixins";

app-user-reset-password-done {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-y: scroll;
    background-color: #f6f6f6;

    #box-button-actions {
        margin-top: calc(44px - 1.34375em);

        button {
            width: 100%;
            height: 56px;
            border-radius: 2px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;

            color: #ffffff;
            .mat-button-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    #form-container {
        margin: 32px auto 0px auto;
        width: 286px;
    }

    .animation {
        width: 46px;
    }

    #welcome-container {
        height: auto;
        margin-top: 24px;
        text-align: center;

        h2 {
            width: 185px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            margin: auto;
            max-width: 273px;
        }
    }

    .panel#user-reset-password-done {
        max-width: 366px;
        flex: 1;
        // height: fit-content;
        max-height: 609px;
        margin-top: 5.8vh; //auto;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: 360px) {
        .panel#user-reset-password-done {
            max-width: 100vw;
            min-width: 288px;
            margin-bottom: unset;
            margin-top: unset;
            background-color: unset;
        }
    }

    @media screen and (max-height: 700px) {
        .panel#external-user-done {
            // margin-bottom: auto;
            margin-bottom: 5.8vh;
            min-height: 90vh;
            height: fit-content;
        }
    }
}
