app-invite-pending {
  .form-container.form-container-filled {
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill {
    width: 100%;

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        .mdc-floating-label {
          color: #48586c;
        }
        .mat-mdc-input-element {
          font-size: 14px;
          color: #333333;
          font-weight: 700;
        }
        .mat-mdc-select .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
        }
      }
    }
  }

  // form
  //   .mat-form-field.mat-form-field-appearance-fill
  //   .mat-mdc-text-field-wrapper
  //   .mat-mdc-form-field-flex {
  //   padding: 12px 16px 8px;
  //   background-color: white;
  //   .mat-mdc-form-field-infix {
  //     .mat-input-element:disabled {
  //       color: #000;
  //     }
  //     span.mat-form-field-label-wrapper {
  //       label.mat-form-field-label {
  //         color: #666666;
  //         font-weight: 400;
  //         font-size: 14px;
  //         line-height: 120%;
  //       }
  //     }
  //   }
  // }
  // form
  //   .form-editable-invite
  //   .mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled
  //   .mat-mdc-text-field-wrapper
  //   .mat-mdc-form-field-flex {
  //   background-color: rgba(0, 0, 0, 0.02);
  // }

  // mat-form-field.selectLanguageFormField {
  //   .mat-select-min-line {
  //     color: #333333 !important;
  //   }

  //   .mat-select-arrow {
  //     visibility: hidden;
  //   }
  // }
}
