@import "mixins";

app-external-user-name {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: #f6f6f6;

  button#onlydocument {
    box-shadow: none;
  }

  button#onlydocument:not([disabled="true"]) {
    background-color: var(--primary-color);
    color: #ffffff;
  }
  button#onlydocument[disabled="true"] {
    background-color: #dbdbdb;
    color: #666666;
  }

  .animation-conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    p.verify-code {
      color: #747474;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.02em;
      @include no-select;
    }
  }

  .animation {
    width: 46px;
  }

  #welcome-container {
    height: auto;
    margin-top: 24px;
    text-align: center;

    h2 {
      width: 185px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin: auto;
      max-width: 273px;
    }
  }

  #form-container {
    margin: 32px auto 0px auto;
    width: 286px;
    form {
      .readonly {
        .mat-input-element {
          color: #48586c;
          box-shadow: 0 0 0 30px #dbdbdb inset;
        }

        .mat-mdc-form-field-flex {
          background-color: #dbdbdb;
        }

        input {
          color: #aeaeae;
        }
        input:-webkit-autofill {
          -webkit-text-fill-color: #aeaeae;
        }
      }

      app-form-control {
        .mat-mdc-form-field-flex {
          background-color: #ffffff;
          border: 1px solid #aeaeae;
          box-sizing: border-box;
          border-radius: 2px;
          height: 62px;
          font-weight: bold;
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
        }
        &[disabled="true"] {
          .mat-mdc-form-field-flex {
            border: unset;
            label {
              color: #aeaeae;
            }
            .mat-form-field-suffix {
              height: 100%;
              margin: auto;
            }
          }
        }
      }

      #box-button-actions {
        margin-top: calc(44px - 1.34375em);

        button {
          width: 100%;
          height: 56px;
          border-radius: 2px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }
    }
  }

  .panel#external-user-name {
    max-width: 366px;
    flex: 1;
    max-height: 609px;
    margin-top: 5.8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }

  #box-button-actions {
    margin-top: calc(44px - 1.34375em);

    button {
      width: 100%;
      height: 56px;
      border-radius: 2px;
      .mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .panel#external-user-name {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#external-user-name {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
