app-invite-pending {
  .form-container.form-container-filled {
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .form-editable-invite {
    .mat-mdc-form-field.mat-form-field-appearance-fill {
      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--filled.mdc-text-field--disabled {
          background-color: lightgray;
        }
      }
    }
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill {
    width: 100%;

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-hint-wrapper {
      padding: 0;
      .mat-mdc-form-field-hint-spacer {
        display: none;
      }
    }

    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        .mdc-floating-label {
          color: #48586c;
        }
        .mat-mdc-input-element {
          font-size: 14px;
          color: #333333;
          font-weight: 700;
        }
        .mat-mdc-select .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
        }
      }
    }
  }
}
