app-external-user-edit {
  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
    .mat-mdc-form-field-hint-spacer {
      display: none;
    }
  }

  .form-invite:not(.filledInvitation) {
    .mat-mdc-form-field.mat-form-field-disabled.mat-form-field-appearance-fill,
    .mat-mdc-form-field.document-field.mat-form-field-appearance-fill,
    .mat-mdc-form-field.disabled-user-email.mat-form-field-appearance-fill {
      .mat-mdc-text-field-wrapper {
        background-color: rgba(102, 102, 102, 0.3);
        .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
          .mdc-floating-label {
            color: #666666;
          }
          .mat-mdc-input-element {
            color: #666666;
          }
          .mat-mdc-select .mat-mdc-select-trigger {
            .mat-mdc-select-value {
              color: #666666;
            }
          }
        }
      }
    }
  }

  .filledInvitationForm {
    .mat-mdc-form-field-subscript-wrapper,
    .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-select-value-text {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }

      .mdc-line-ripple {
        display: none;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input,
          .input-email {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }

      &[disabled="true"] {
        .mat-mdc-form-field-flex {
          border: unset;
          label {
            color: #aeaeae;
          }
          .mat-form-field-suffix {
            height: 100%;
            margin: auto;
          }
        }
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  div.footer-container {
    div.footer-button {
      button.continue-button {
        span.mat-button-wrapper {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.02em;
        }
      }
    }
  }
}
