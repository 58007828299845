.logs-description.cdk-overlay-pane {
  .mat-dialog-container {
    background: #f6f6f6;
  }

  .mat-mdc-dialog-surface {
    padding: 40px;
    background-color: #f5f5f5;
    max-width: 94vw;
    overflow: auto;
    max-height: 94vh;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
  }

  .parent table.mat-mdc-table[id="22"] {
    pointer-events: none;
    thead tr {
      background: white;
      th {
        border-bottom: none;
        &:first-of-type {
          padding-left: 32px;
        }
        &:last-of-type {
          padding-right: 32px;
        }
      }
    }

    tbody[role="rowgroup"] {
      background: white;
      tr {
        background: white;
        td {
          border-bottom: 3px solid #f6f6f6;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 40px;
          cursor: pointer;
          &:active {
            cursor: -webkit-grab;
            cursor: grab;
          }
          &:first-of-type {
            padding-left: 32px;
          }
          &:last-of-type {
            padding-right: 32px;
          }
        }
      }
    }
  }

  .mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            .mat-tab-label {
              &.mat-tab-label-active {
                opacity: 1;
                .mat-tab-label-content {
                  color: #ff6600;
                }
              }

              .mat-tab-label-content {
                color: #aeaeae;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.82px;
                letter-spacing: -0.02em;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      .mat-tab-body {
        .mat-tab-body-content {
          .parent {
            .mat-table {
              .mat-row.mat-no-data-row {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}
