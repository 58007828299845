.group-handle-panel.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 94vw;
  max-height: 94vh;

  .mat-mdc-dialog-surface {
    background-color: #f5f5f5;
    width: 94vw;
    overflow: auto;
    max-height: 94vh;
    box-shadow: none;
    padding: 20px;
    max-width: 888px;

    .panel {
      .mat-mdc-form-field {
        flex: 1;
      }
    }

    .actions-buttons-container {
      button {
        font-weight: bold;
        box-shadow: none;
        border-radius: 2px;
        color: #333333;

        &.create-button {
          color: #ffffff;
        }
      }
    }
  }
}
