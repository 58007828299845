app-form-change-password {
  form mat-card.container-mat.mat-mdc-card:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .mat-mdc-card .mat-mdc-card-actions button.mdc-button {
    --mdc-protected-button-label-text-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  #buttonChangePassword {
    box-shadow: none;
    span.mat-button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
