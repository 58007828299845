@import "mixins";

app-user-confirmation-mfa {
  width: fit-content;
  height: fit-content;
  display: flex;
  overflow-y: scroll;
  background-color: #f6f6f6;

  .animation-conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    p.verify-code {
      color: #747474;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.02em;
      @include no-select;
    }
  }

  .animation {
    width: 46px;
  }

  .panel#user-confirmation-mfa {
    max-width: 366px;
    flex: 1;
    // height: fit-content;
    max-height: 609px;
    // margin-top: 5.8vh; //auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    #welcome-container {
      height: auto;
      margin-top: 24px;
      text-align: center;

      h2 {
        width: 185px;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        margin: auto;
        max-width: 273px;
      }
    }
    #form-container {
      margin: 52px auto 0px auto;
      width: 286px;
      form {
        .readonly {
          // border-radius: 2px;

          .mat-input-element {
            color: #48586c;
            box-shadow: 0 0 0 30px #dbdbdb inset;
          }

          .mat-mdc-form-field-flex {
            background-color: #dbdbdb;
          }

          input {
            color: #aeaeae;
          }
          input:-webkit-autofill {
            -webkit-text-fill-color: #aeaeae;
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .panel#user-confirmation-mfa {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#user-confirmation-mfa {
      // margin-bottom: auto;
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
