app-fiserv-internal-details {
  .page {
    main.content {
      .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .panel {
          mat-form-field {
            .mat-mdc-form-field-flex {
              background-color: white !important;
              .mat-mdc-form-field-infix {
                width: auto;
              }
            }
          }
        }
        mat-divider.mat-divider-inset {
          margin-left: 0px;
          margin-top: 28px;
        }
      }
    }
  }
}
