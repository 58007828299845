app-form-control {
  mat-form-field.mat-form-field-appearance-fill {
    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      text-align: end;
    }
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }

      .mdc-line-ripple {
        display: none;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }

      &[disabled="true"] {
        .mat-mdc-form-field-flex {
          border: unset;
          label {
            color: #aeaeae;
          }
          .mat-form-field-suffix {
            height: 100%;
            margin: auto;
          }
        }
      }
    }
  }
}
