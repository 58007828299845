app-optin {
    button.optin-confirm-button {
        span.mat-button-wrapper {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media (max-width: 582px) {
      
        .panel {
        
             button.optin-confirm-button {
         
                 span.mat-button-wrapper {
                     height: 56px;
                 }
             }
        }
     
    }
}
