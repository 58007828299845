:root {
    /* Font Size */
    --font-size-low: 12px;
    --font-size-mid: 14px;
    --font-size-large: 16px;
    --font-size-extra-large: 20px;
    --font-size-big: 32px;

    /* Font Weight */
    --font-weight-low: 500;
    --font-weight-mid: 400;
    --font-weight-large: 700;

    /* Font Line-height */
    --font-line-height-low: 14px;
    --font-line-height-mid: 17px;
    --font-line-height-large: 20px;
    --font-line-height-extra-large: 25px;
    --font-line-height-percent-low: 120%;

    /* Font Letter-spacing */
    --font-letter-spacing-low: -0.02em;

    /* Border-radius */
    --border-radius-low: 2px;

    /* Colors */
    --color-aqua-haze: #eff5f6;
    --color-blaze-orange: #ff6600;
    --color-cloud-burst: #1f2a47;
    --color-charcoal-gray: #666666;
    --color-dark-orange: #f39200;
    --color-dusk: #48586c;
    --color-gainsboro: #dbdbdb;
    --color-papaya-orange: #e06e1d;
    --color-graphite-gray: #333333;
    --color-white: #ffffff;
    --color-teal-blue: #005aa3;
    --color-black: #000000;
    --color-orange: #ff6600;
    --color-anti-flash-white: #f2f2f2;
    --color-silver-chalice: #aeaeae;
    --color-wild-sand: #f6f6f6;
    --color-silver-oil: #aeaeae;
    --color-light-grayish-red: #ed0722;
    --color-nice-blue: #007eab;
    --color-cloud-burst-bt: #21324d;
    --color-cloud-burst2-bt: #1c344f;
    --color-cornflower-blue-bt: #6195ed;
    --color-botticelli-bt: #c8dae1;
    --color-geyser-bt: #cbd9e0;

    /*hipercard*/
    --color-vanilla-ice: #f6e3e4;
    --color-rose-fog: #e8b8bb;
    --color-petite-orchid: #d9898d;
    --color-chestnut-rose: #ca5a5f;
    --color-well-read: #be363d;
    --color-thunderbird: #b3131b;
    --color-tamarillo: #a30e14;
    --color-totem-pole: #9a0b10;
    --color-dark-burgundy: #8b0608;
    --color-sundown: #ffb8b8;
    --color-vivid-tangerine: #ff8586;
    --color-sunset-orange: #ff5253;
    --color-red-orange: #ff383a;
    --color-timberwolf: #d9d3cf;

    --color-silver-oil: #aeaeae;
    --color-green-blue: #0f6cae;
    --color-ocean-boat-blue: #0076c0;
    --color-light-gray: #ced4da;
    --color-davy-grey: #495057;
    --color-midnight-blue: #004388;
    --color-medium-violet-red: #d01481;
    --color-light-silver: #d8d8d8;
    --color-wenge: #605751;
    --color-battery-charged-blue: #23b9e2;
    --color-spanish-yellow: #fdb913;
    --color-AuroMetalSaurus: #6c757d;
    --color-silver-sand: #c4c4c4;
    --color-heat-wave: #ff7a00;
    --color-bright-gray: #e6e7f0;
    --color-cultured: #f5f6fa;
    --color-cool-grey: #8a96a8;
    --color-eerie-black: #231d19;
    --color-spanish-orange: #ec7000;
    --color-dark-liver: #56504c;
    --color-metallic-seaweed: #10888f;
    --color-american-silver: #cdd3cd;
    --color-ebony: #5a645a;
    --color-yellow-green: #3fa110;
    --color-onyx: #343a40;
    --color-light-gray2: #d2d2d2;
    --color-green: #76b82a;
    --color-gunmetal: #293340;
    --color-turquoise-surf: #00c6cb;
    --color-star-command-blue: #007ab7;
    --color-carnelian: #ba1319;
    --color-turquoise-surf2: #00c6cc;
    --color-robins-egg-blue: #00c6cc;
}
