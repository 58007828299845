app-fiserv-date-institution {
  #first-box app-file-upload {
    display: flex;
    div.upload-file-comp {
      height: calc(100% - 1.4em);
      min-height: 217px;
    }
    div {
      .preview-container {
        height: 233px;
      }
      .action-box {
        bottom: 250px;
      }
    }
  }

  .domain-container {
    display: flex;
    margin-top: 1.34375em;
    mat-form-field.mat-form-field-appearance-fill {
      flex: 1;
      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        background-color: var(
          --app-form-control-input-mat-form-field-background-color,
          white
        );
        border: var(--app-form-control-input-mat-form-field-flex-border);
        color: #eff5f6;
        box-sizing: var(
          --app-form-control-input-mat-form-field-flex-box-sizing
        );
        border-radius: 2px;
        width: var(--app-form-control-input-mat-form-field-flex-width);
        flex: 1 1;
        display: flex;
        padding: 12px 16px 8px;
        height: 42px;
        align-items: center;
        .mat-mdc-form-field-infix {
          padding: 0.25em 0 0.25em 0;

          input {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            color: #333333;
            font-weight: 700;
            font-style: normal;
          }
          mat-label {
            color: #48586c;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
  button.submit-institution-data {
    .mat-button-wrapper {
      display: flex;
      justify-content: space-between;
      flex: 1;
      align-items: center;
    }
  }
}
