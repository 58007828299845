@use "@angular/material" as mat;
@import "mixins";
@import "../abstracts/_palettes.scss";

html[theme="fiserv"] {
  // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
  //  The following line adds:
  //    1. Default typography styles for all components
  //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
  //  If you specify typography styles for the components you use elsewhere, you should delete this line.
  //  If you don't need the default component typographies but still want the hierarchy styles,
  //  you can delete this line and instead use:
  //    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
  // @include mat.all-component-typographies();
  // @include mat.core();
  // $primary: mat.define-palette($linen-to-black);
  // $accent: mat.define-palette(mat.$amber-palette, 500, 900, A100);
  // $warn: mat.define-palette(mat.$deep-orange-palette);

  // $theme: mat.define-light-theme(
  //   (
  //     color: (
  //       primary: $primary,
  //       accent: $accent,
  //       warn: $warn,
  //     ),
  //   )
  // );

  /* Overrides variables from pages */
  // External Login Page
  // --external-login-input-webkit-autofill: rgba(0, 0, 0, 0.04);
  // --external-login-welcome-container-h2-color: var(--color-teal-blue);
  // --external-login-forget-password-link-color: var(--color-teal-blue);
  // --external-login-buttons-password-email-reflect-disabled-false-bg-color: var(--color-dark-orange);
  // --external-login-buttons-password-email-reflect-disabled-false-color: var(--color-white);
  // --external-login-buttons-password-email-reflect-disabled-true-bg-color: var(--color-gainsboro);
  // --external-login-buttons-password-email-reflect-disabled-true-color: var(--color-charcoal-gray);
  // --external-login-first-access-button-bg-color: var(--color-aqua-haze);
  // --external-login-first-access-button-color: var(--color-graphite-gray);

  // app-external-login {
  //     --app-form-control-mat-form-field-flex-border: "transparent";
  //     --app-form-control-mat-form-field-flex-box-sizing: "none";
  //     --app-form-control-input-mat-form-field-background-color: var(--color-aqua-haze);
  //     --app-form-control-input-mat-form-field-flex-width: calc(285px - 32px);
  // }

  --primary-color: var(--color-orange);

  // User Forget Password Page

  app-user-forgot-password {
    --app-form-control-input-mat-form-field-flex-border: 1px solid
      var(--color-silver-oil);
    --app-form-control-mat-form-field-flex-box-sizing: border-box;
  }

  --user-forgot-password-form-container-button-continue-button-disabled-false-bg-color: var(
    --color-dark-orange
  );
  --user-forgot-password-form-container-button-continue-button-disabled-false-color: var(
    --color-white
  );

  --user-forgot-password-form-container-button-continue-button-disabled-true-color: var(
    --color-charcoal-gray
  );

  // External User List Page

  // --app-external-user-list-nav-mat-tab-label-active-color: var(--color-dark-orange);
  // --app-external-user-list-menu-filter-button-activated-filter-color: var(--color-dark-orange);
  // --app-external-user-list-menu-filter-button-activated-filter-border: 1px solid var(--color-dark-orange);

  // Fiserv Login Page
  app-fiserv-login {
  }

  // User Forget Password Page
  app-user-forgot-password {
    --user-forgot-password-form-container-button-continue-button-disabled-false-bg-color: var(
      --color-blaze-orange
    );
    --user-forgot-password-form-container-button-continue-button-disabled-false-color: var(
      --color-white
    );
    --user-forgot-password-form-container-button-continue-button-disabled-true-bg-color: var(
      --color-gainsboro
    );
    --app-form-control-input-mat-form-field-flex-border: 1px solid
      var(--color-silver-oil);
    --app-form-control-mat-form-field-flex-box-sizing: border-box;
    --app-form-control-input-mat-form-field-flex-width: calc(285px - 32px);
    --app-user-forgot-password-mat-step-header-stepper-horizontal-line: var(
      --color-blaze-orange
    );
  }

  // .mat-checkbox-background {
  //   background-color: transparent !important;
  //   svg .mat-checkbox-checkmark-path {
  //     stroke: black !important;
  //   }
  // }

  // Language-selector Component
  --languege-selector-options: var(--color-aqua-haze);
  --language-selector-span-text-info-selected-language-color: var(
    --color-cloud-burst
  );

  // @include mat.all-component-themes($theme);
}
