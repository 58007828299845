app-fiserv-access-management {
  mat-tab-group.mat-group-man
    .mat-tab-body-wrapper
    mat-tab-body.mat-tab-body-active {
    overflow-y: hidden;
  }
  .mat-tab-body-content {
    overflow: hidden;
  }
  .search-service-contract-input {
    left: 50px;
  }
  .toast-importation {
    display: flex;
    justify-content: space-between;
    height: 24px;
    background-color: #219653;
    padding: 16px 24px;
    margin: 32px 0px;
    .information {
      display: flex;
      span.text {
        margin: auto auto auto 16px;

        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }
    .close-box {
      button {
        height: 24px;
        width: 24px;
        display: flex;
        .mat-button-wrapper {
          display: flex;
          mat-icon svg line {
            stroke: #fff;
          }
        }
      }
    }
  }
  div.mat-tab-body-content app-file-upload {
    div.upload-file-comp {
      max-width: unset;

      p {
        margin: 0;
        max-width: 254px;
        text-align: center;
        font-size: 12px;
        .search-info {
          text-decoration: underline;
        }
      }
    }
  }

  .mat-tab-list
    .mat-tab-labels
    .mat-ripple.mat-tab-label.mat-focus-indicator:not(.mat-tab-label-active) {
    .mat-tab-label-content {
      color: #aeaeae;
    }
  }

  .card-channel-info {
    mat-form-field.mat-form-field .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        width: 366px;
        max-width: 77vw;
        background-color: #dbdbdb;
        border-radius: 2px;

        .mat-mdc-form-field-infix {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        span.mat-select-min-line {
          color: #666666;
        }

        .mat-mdc-form-field-infix {
          .mat-form-field-label-wrapper {
            label {
              mat-label {
                color: #666666;
              }
            }
          }
        }
      }
    }
  }
  .page main.content mat-tab-group {
    margin-bottom: 20px;

    form#search.search-service-contract {
      align-items: center;
      mat-form-field.mat-form-field {
        .mat-mdc-text-field-wrapper {
          padding-bottom: 0px;
          .mat-mdc-form-field-infix {
            display: flex;
          }
        }
      }
      .cancel-button {
        width: 14px;
        height: 14px;
        .mat-button-wrapper {
          display: flex;
        }
        mat-icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    mat-tab-header {
      margin-bottom: 32px;
      .mat-tab-label-container
        .mat-tab-list
        .mat-tab-labels
        .mat-tab-label-content {
        color: #ff6600;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 556px) {
    .messageBlockMobile {
      height: 18px;
      padding: 13px 17px;
      background-color: #219653;
      z-index: 1;
      position: absolute;
      width: calc(100vw - 34px);
      display: flex !important;
      align-items: center;
      p {
        margin: 0 0 0 9px !important;
        color: #ffffff;
        font-size: 14px;
        line-height: 17px;
      }
      .close-box {
        display: flex;
        margin-left: auto;
        button {
          width: 24px;
          height: 24px;
          display: flex;
          .mat-button-wrapper {
            display: flex;
            line {
              stroke: white;
            }
          }
        }
      }
    }

    .toast-importation {
      .information {
        display: flex;
        span.text {
          margin: auto auto auto 16px;

          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          color: #fff;
        }
      }
      .close-box {
        button {
          height: 24px;
          width: 24px;
          display: flex;
          .mat-button-wrapper {
            display: flex;
            mat-icon svg line {
              stroke: #fff;
            }
          }
        }
      }
    }

    .page {
      .main-toolbar {
        img {
        }

        .persona_info {
          app-user-name-authenticate {
          }
          .channel-name {
          }

          mat-icon {
          }
          span {
          }
        }
      }
      main.content {
        .mat-tab-label-content {
          font-size: 16px;
        }
        .card-channel-info {
          mat-form-field {
            width: 100%;
            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
              width: 100%;
              max-width: none;
            }
          }
        }
        h2.channel-selected {
        }

        .title {
          .add-user-mob {
          }
          div.title-container {
            .title-channel {
            }
            .add-user {
              span.mat-button-wrapper {
              }
            }

            h1 {
            }
          }
        }
        .subtitle {
        }

        .information-container {
        }

        .table-title {
        }
      }
    }
  }
}

.fiserv-modal-warning-importation-list {
  .content-modal-warning {
    display: flex;
    flex-direction: column;
    margin: auto;
    .header-modal {
      display: flex;
      justify-content: center;
      .mat-mdc-dialog-title {
        margin: 0;
        padding: 0;
        height: 30px;
        display: flex;
      }
    }
    .icon-container {
      mat-icon.mat-icon svg path {
        fill: #ff6600;
      }
    }
  }
}

.cdk-global-overlay-wrapper .upload-float-file {
  mat-dialog-container.mat-mdc-dialog-container {
    background-color: #f6f6f6;
    .panel-upload {
      margin: 0;
      background-color: #f6f6f6;
      padding: 16px;

      align-items: flex-start;
      gap: 16px;
      border-radius: 2px;

      display: grid;
      max-width: 1078px;
      width: 50vw;

      .new-importation-text {
        display: flex;
        flex-direction: column;
        .close-box {
          display: flex;
          justify-content: flex-end;
        }
        h1 {
          margin: 0 0 8px;
          color: #333333;
        }
        p {
          margin: 0;
          color: #333333;
        }
      }
      .upload-cancel {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0px 24px 0;
        button {
          width: 96px;
          height: 56px;
          font-weight: bold;
        }
      }

      .animation-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px dashed #aeaeae;
        height: 217px;
        justify-content: center;

        p.saving {
          margin: 4px 0;
          color: #ff5b10;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.02em;
        }
      }
      .alert-a {
        display: none;
      }

      app-file-upload {
        margin: 0;
        .upload-file-comp {
          max-width: unset;
          padding: 10px;
          p {
            margin: 0;
            max-width: 254px;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.cdk-global-overlay-wrapper .cdk-overlay-pane.data-confirm-list {
  overflow-y: auto;
  max-height: 90vh;
  border-radius: 2px;
  mat-dialog-container.mat-dialog-container {
    background: #f6f6f6;
    border-radius: unset;
  }
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .autoCompleteSearchInsertServiceContractAndInstitution {
  left: 98px !important;
  margin: 20px 0;
}

.cdk-overlay-container
  .cdk-global-overlay-wrapper
  .cdk-overlay-pane.confirm-cancel-upload {
  .mat-dialog-container {
    padding: 40px;

    .header-modal {
      width: 316px;
      margin: auto;
      h2 {
        line-height: normal;
      }
    }
    .mat-dialog-content {
      width: 313px;
      margin: auto;
    }
    .controls-modal {
      margin-top: 19px;
      button {
        &:first-child {
          width: 163px;
        }
        width: 166px;
      }
    }
  }
}
.cdk-overlay-container
  .cdk-global-overlay-wrapper
  .cdk-overlay-pane.confirm-resend-imported-invite {
  .mat-dialog-container {
    padding: 40px;
    overflow: hidden;
    .header-modal {
      width: 316px;
      margin: auto;
      h2 {
        line-height: normal;
      }
    }
    .mat-dialog-content {
      width: 313px;
      margin: auto;
    }
    .controls-modal {
      margin-top: 19px;
      button {
        &:first-child {
          width: 163px;
        }
        width: 166px;
      }
    }
  }
}

@media (max-width: 556px) {
  .cdk-overlay-container
    .cdk-global-overlay-wrapper
    .cdk-overlay-pane.confirm-resend-imported-invite {
    .mat-dialog-container {
      padding: 40px;
      overflow: hidden;
      min-height: 286px;
      .header-modal {
        max-width: fit-content;
        margin: auto;
      }
      .mat-dialog-content {
        display: none;
      }
      .controls-modal {
        display: flex;
        flex-direction: column-reverse;
        gap: 24px;
        button {
          width: 100%;
          margin: 0;

          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }

  .cdk-overlay-container
    .cdk-overlay-connected-position-bounding-box
    .autoCompleteSearchInsertServiceContractAndInstitution {
    left: 16px !important;
  }
}
