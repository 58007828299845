app-fiserv-institution-channel {
  .mat-tab-label .mat-tab-label-content {
    font-weight: bold;
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-select-value-text {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input,
          .input-email {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }
    }
  }

  main.content {
    .mat-tab-label-active {
      color: #ff6600;
    }

    .filter-container {
      .add-property-button {
        .mat-button-wrapper {
          display: flex;
          width: inherit;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .mat-no-data-row {
      height: 100px;
      td {
        word-break: break-all;
      }
    }

    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: auto;

      table {
        width: 100%;
        margin-bottom: 1rem;
        display: table;
        border-collapse: collapse;
        margin: 0px;
      }
      mat-row,
      mat-header-row {
        display: table-row;
      }
      mat-cell,
      mat-header-cell {
        word-wrap: initial;
        display: table-cell;
        padding: 0px 5px;
        line-break: unset;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
      }
    }

    .table-bottom .pagination-controls {
      button {
        min-width: unset;
      }
      svg {
        height: 60%;
        margin-bottom: 4px;
      }
    }

    .card-container {
      app-card-channel .mat-mdc-card {
        box-shadow: none;
      }
    }
  }
  .add-institution,
  .add-channel {
    .mat-button-wrapper {
      display: flex;
      width: inherit;
      justify-content: space-between;
      align-items: center;
    }
  }

  .filter-container {
    margin: 24px 0 0 0;
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    .key-filter,
    .channel-filter {
      &.mat-form-field-appearance-fill
        .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex {
        background-color: #ffffff;
      }
    }
    .key-filter {
      min-width: 366px;
    }
  }
}
