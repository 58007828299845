app-user-invite {
  .messageBlock {
    .mat-icon {
      width: 20px;
      height: 20px;
    }
  }
  .continue-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
    .mat-mdc-form-field-hint-spacer {
      display: none;
    }
  }

  .filledInvitationForm {
    .mat-mdc-form-field-subscript-wrapper,
    .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-select-value-text {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }

      .mdc-line-ripple {
        display: none;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }

      &[disabled="true"] {
        .mat-mdc-form-field-flex {
          border: unset;
          label {
            color: #aeaeae;
          }
          .mat-form-field-suffix {
            height: 100%;
            margin: auto;
          }
        }
      }
    }
  }

  // .form-invite
  //   mat-form-field.selectedUserType
  //   .mat-mdc-text-field-wrapper
  //   .mat-mdc-form-field-flex {
  //   background-color: var(
  //     --app-form-control-input-mat-form-field-background-color,
  //     white
  //   );
  //   border: var(--app-form-control-input-mat-form-field-flex-border);
  //   box-sizing: var(--app-form-control-input-mat-form-field-flex-box-sizing);
  //   border-radius: 2px;
  //   width: var(--app-form-control-input-mat-form-field-flex-width);
  //   flex: 1 1;
  //   display: flex;
  //   padding: 12px 16px 8px;
  //   height: 42px;
  //   align-items: center;
  // }
  // app-form-control
  //   mat-form-field
  //   .mat-mdc-text-field-wrapper
  //   .mat-mdc-form-field-flex,
  // .selectedUserType .mat-mdc-form-field-flex {
  //   background-color: #eff5f6;
  // }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //   -webkit-box-shadow: 0 0 0 30px white inset;
  // }
  // .filledInvitationForm .filledInvitation {
  //   overflow: hidden;
  //   mat-form-field {
  //     flex: 0 !important;
  //     height: 62px;
  //     .mat-mdc-text-field-wrapper {
  //       .mat-mdc-form-field-flex {
  //         padding: 0.75em 0.75em 0 0.75em !important;
  //       }
  //     }
  //   }
  // }
  // mat-form-field.inputCpforTaxId {
  //   flex: 1;
  //   .mat-mdc-text-field-wrapper {
  //     .mat-mdc-form-field-flex {
  //       background-color: var(
  //         --app-form-control-input-mat-form-field-background-color,
  //         white
  //       );
  //       border: var(--app-form-control-input-mat-form-field-flex-border);
  //       color: #333333;
  //       box-sizing: var(
  //         --app-form-control-input-mat-form-field-flex-box-sizing
  //       );
  //       border-radius: 2px;
  //       width: var(--app-form-control-input-mat-form-field-flex-width);
  //       flex: 1 1;
  //       display: flex;
  //       padding: 12px 16px 8px;
  //       height: 42px;
  //       align-items: center;
  //     }

  //     .mat-form-field-subscript-wrapper {
  //       padding: 0px;
  //       .mat-form-field-hint-spacer {
  //         display: none;
  //       }
  //       mat-hint {
  //         display: grid;
  //         grid-template-columns: auto 1fr;
  //         grid-gap: 10px;
  //         svg {
  //           width: 20px;
  //           height: 20px;
  //         }
  //       }
  //     }
  //   }
  // }

  // mat-form-field.selectLanguageFormField {
  //   flex: 1;
  //   .mat-mdc-text-field-wrapper {
  //     .mat-mdc-form-field-flex {
  //       background-color: var(
  //         --app-form-control-input-mat-form-field-background-color,
  //         white
  //       );
  //       border: var(--app-form-control-input-mat-form-field-flex-border);
  //       box-sizing: var(
  //         --app-form-control-input-mat-form-field-flex-box-sizing
  //       );
  //       border-radius: 2px;
  //       width: var(--app-form-control-input-mat-form-field-flex-width);
  //       flex: 1 1;
  //       display: flex;
  //       padding: 12px 16px 8px;
  //       height: 42px;
  //       align-items: center;
  //     }

  //     .mat-form-field-subscript-wrapper {
  //       padding: 0px;
  //       .mat-form-field-hint-spacer {
  //         display: none;
  //       }
  //       mat-hint {
  //         display: grid;
  //         grid-template-columns: auto 1fr;
  //         grid-gap: 10px;
  //         svg {
  //           width: 20px;
  //           height: 20px;
  //         }
  //       }
  //     }
  //   }
  // }

  // mat-form-field {
  //   .mat-mdc-form-field-infix {
  //     padding: unset !important;
  //     border: unset;
  //     height: 20px;
  //   }
  //   label {
  //     padding-top: 5px;
  //   }
  //   .mat-mdc-form-field-flex {
  //     padding: 21px 12px 21px 12px;
  //   }
  //   input {
  //     margin-top: 8px !important;
  //   }
  //   .mat-select-trigger {
  //     margin-top: 8px !important;
  //   }

  //   &.mat-form-field-disabled {
  //     background-color: #ffffff;
  //     .mat-mdc-text-field-wrapper {
  //       padding-bottom: unset;
  //     }
  //     input,
  //     .mat-select-value-text {
  //       color: #333333 !important;
  //       font-weight: bold;
  //     }
  //     .mat-select-arrow {
  //       color: #ffffff !important;
  //     }
  //     .mat-mdc-form-field-flex {
  //       background-color: #ffffff !important;
  //     }
  //     label {
  //       color: #666666 !important;
  //     }
  //   }
  // }
  div.footer-container {
    div.footer-button {
      button.continue-button {
        box-shadow: none;
        span.mat-button-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
