@use "@angular/material" as mat;
@import "mixins";
@import "./styles/abstracts/_palettes.scss";

@include mat.core();

@font-face {
  font-family: "Univers From Fiserv";
  src: url("./assets/fonts/UniversForFiserv45-Lt.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Univers From Fiserv";
  src: url("./assets/fonts/UniversForFiserv55.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

$primary-plt: (
  50: var(--palette-50, #ffede0),
  100: var(--palette-100, #ffd1b3),
  200: var(--palette-200, #ffb380),
  300: var(--palette-300, #ff944d),
  400: var(--palette-400, #ff7d26),
  500: var(--palette-500, #ff6600),
  600: var(--palette-600, #ff5e00),
  700: var(--palette-700, #ff5300),
  800: var(--palette-800, #ff4900),
  900: var(--palette-900, #ff3800),
  A100: var(--palette-A100, #ffffff),
  A200: var(--palette-A200, #fff4f2),
  A400: var(--palette-A300, #ffc9bf),
  A700: var(--palette-A700, #ffb4a6),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary-palette: mat.define-palette($primary-plt);
$accent-palette: mat.define-palette(mat.$amber-palette, 500, 900, A100);

$warn-palette: mat.define-palette(mat.$red-palette);

$open-sans-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Univers From Fiserv"',
);

@include mat.typography-hierarchy($open-sans-typography);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $open-sans-typography,
  )
);

@include mat.all-component-themes($my-theme);

a {
  --mat-text-button-hover-state-layer-opacity: 0;
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
  --mat-text-button-hover-state-layer-opacity: 0;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", "Univers From Fiserv";
}

input {
  font-family: "Open Sans", "Univers From Fiserv";
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #ffffff;
  opacity: 0.75 !important;
}

mat-error.mat-error {
  text-align: end;
}
.mat-mdc-form-field-infix {
  input,
  .mat-select-value {
    font-weight: 700;
  }
}
.mat-form-field-underline {
  display: none;
}

.renewal-warning mat-dialog-container.mat-dialog-container {
  min-width: 392px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 7.875rem;
  padding: 0;
  app-modal-warning-renewal {
    flex: 1;
  }
  div.controls-modal {
    justify-content: center;
  }
  .close-container {
    display: flex;
  }
}
.renewal-users-list mat-dialog-container.mat-dialog-container {
  app-modal-renewal .content-modal {
    .actions-container {
      mat-form-field.mat-form-field-can-float {
        flex: 1;
        max-width: 272px;
        margin-top: 24px;
        .mat-mdc-text-field-wrapper {
          flex: 1;
          .mat-mdc-form-field-flex {
            background-color: white;
            border: 1px solid #aeaeae;
            border-radius: 2px;
            .mat-form-field-suffix mat-icon[svgicon="close"] {
              display: none;
            }
          }
        }
      }
      .select-all-container {
        margin-top: 14px;
        transition: margin 0.5s;
        -webkit-transition: margin 0.5s;
        .select-all-users {
          &:hover {
            .mat-button-focus-overlay {
              opacity: 0;
            }
          }
        }
      }
    }
    .container-button-confirm {
      button.confirm {
        border-radius: 2px;
      }
    }
  }
}

@media (max-width: 556px) {
  .renewal-users-list mat-dialog-container.mat-dialog-container {
    app-modal-renewal .content-modal {
      .mat-dialog-content,
      .header-modal {
        text-align: center;
      }
      .confirmRenew-text-body {
        flex: 1;
      }
      .actions-container {
        mat-form-field.mat-form-field-can-float {
          &.expanded .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
            width: 100%;
            .mat-form-field-suffix {
              mat-icon[svgicon="close"] {
                display: block;
              }
              mat-icon[svgicon="search"] {
                display: none;
              }
            }
          }

          .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
            width: 44px;
            transition: width 0.5s;
            -webkit-transition: width 0.5s;
          }
        }
        .select-all-container {
          margin-top: 24px;
          &.hide {
            display: none;
          }
        }
      }
      .container-button-confirm {
        button.confirm {
          margin-right: auto;
          min-height: 3.5rem !important;
          width: 100% !important;
        }
      }
      .container-buttons-confirm-renew {
        flex-direction: column-reverse;
        gap: 8px;
        button {
          width: 100% !important;
          height: 56px !important;
        }
      }
    }
  }
}

html[theme="fiserv"]
  .property-handle-panel.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-height: 94vh;
  max-width: 94vw;
}

.float-menu-component {
  top: 30px !important;
}

// .cdk-overlay-connected-position-bounding-box {
//   z-index: 999 !important;
// }
