app-invite-user {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .mat-mdc-select-min-line {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #333333;
    font-weight: 700;
    font-style: normal;
  }

  .messageBlock {
    .mat-icon {
      width: 20px;
      height: 20px;
    }
  }

  .form-container:not(.form-container-filled) app-form-control[label="Login"] {
    mat-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
      background-color: #aeaeae;
      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        label,
        input {
          color: white;
          background-color: #aeaeae;
        }
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }

      .mdc-line-ripple {
        display: none;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }

      &[disabled="true"] {
        .mat-mdc-form-field-flex {
          border: unset;
          label {
            color: #aeaeae;
          }
          .mat-form-field-suffix {
            height: 100%;
            margin: auto;
          }
        }
      }
    }
  }

  .panel-admin-radio {
    mat-radio-group {
      mat-radio-button {
        label.mat-radio-label {
          .mat-radio-label-content {
            padding-left: 10px;
          }
        }
      }
    }
  }

  mat-form-field.inputCpforTaxId,
  mat-form-field.selectLanguageFormField {
    flex: 1;
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        background-color: var(
          --app-form-control-input-mat-form-field-background-color,
          white
        );
        border: var(--app-form-control-input-mat-form-field-flex-border);
        box-sizing: var(
          --app-form-control-input-mat-form-field-flex-box-sizing
        );
        border-radius: 2px;
        width: var(--app-form-control-input-mat-form-field-flex-width);
        flex: 1 1;
        display: flex;
        align-items: center;
      }

      .mat-form-field-label-wrapper {
        label {
          color: #48586c;
          font-size: 14px;
          line-height: 15px;
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0px;
        .mat-form-field-hint-spacer {
          display: none;
        }
        mat-hint {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 10px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .mat-checkbox-frame {
    border-radius: 4px !important;
    border-color: #333333 !important;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: transparent !important;
    .mat-checkbox-checkmark-path {
      stroke: #333333 !important;
    }
  }

  .mat-radio-outer-circle {
    border-color: #333333 !important;
  }
  .mat-radio-inner-circle {
    background-color: #333333 !important;
  }

  .form-invite {
    app-form-control {
      &.form-control-email {
        grid-column: span 2;
      }
      &:first-child {
        grid-column: span 2;
      }
      &:nth-child(3) {
        grid-column: span 2;
      }
    }

    &.filledInvitation {
      background-color: #ffffff;
      padding: 1.34375em;

      .mat-form-field-appearance-fill.mat-form-field-disabled
        .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          &.validity {
            input {
              border: none;
              background-color: transparent;
            }
          }

          input {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            color: #333333 !important;
            font-weight: 700;
            font-style: normal;
          }
          .mat-form-field-label-wrapper {
            label {
              color: #48586c;
              font-size: 14px;
              line-height: 15px;
            }
          }

          .mat-select-min-line {
            color: #333333 !important;
          }

          .mat-mdc-select-arrow-wrapper {
            visibility: hidden;
          }
        }
      }
    }
  }

  .footer-container .footer-button {
    .mat-ripple.mat-mdc-button-ripple {
      display: none;
    }
    button.mat-raised-button {
      box-shadow: none;
      &.continue-button span.mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media (max-width: 582px) {
    .subtitle-info {
      display: none;
    }
  }

  @media (min-width: 557px) and (max-width: 582px) {
    div.page {
      main.content {
        padding-bottom: 44px;
        div.form-container {
          app-form.ng-invalid form.ng-invalid div.form-invite {
            grid-template-columns: 1fr !important;
            app-form-control {
              grid-column: span 1;
            }
          }
        }
      }
    }
  }
  @media (min-width: 583px) and (max-width: 770px) {
    div.page {
      main.content {
        padding-bottom: 44px;
        div.form-container {
          app-form.ng-invalid form.ng-invalid div.form-invite {
            grid-template-columns: 1fr 1fr !important;
            app-form-control {
              grid-column: span 1;
            }
          }
        }
      }
    }
  }
}
