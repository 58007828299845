app-fiserv-login {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
  }
  .fiserv-login-form {
    .mat-mdc-form-field-flex {
      // background-color: #ffffff !important;
    }

    mat-label {
      font-size: 14px;
    }
    .mat-form-field-underline {
      display: none;
    }
  }
  #insert-code.panel {
    flex: 1;
    max-width: 366px;
    max-height: 544px;
    // max-height: 92vh;
    margin-top: 5.8vh; //auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 360px) {
    .panel#insert-login {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
      #first-access-button-box {
        margin: auto;
      }
    }
    .panel#insert-code.panel {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }
}
