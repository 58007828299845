app-table-users {
  form
    .mat-form-field
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-infix
    .mat-select
    .mat-select-trigger
    .input {
    top: 4px;
  }
}
