app-fiserv-add-institution {
  .service-contract-container-closed,
  .channel-application-container-closed {
    .card-container {
      app-fiserv-data-card-services-contracts {
        .data-service-contract {
          .details-contract-container {
            padding: 0;
          }
        }
      }
    }
  }

  main.content
    .service-contract-container-closed
    app-fiserv-date-services-contract {
    flex: 1;

    div.data-service-contract {
      div.details-contract-container {
        padding: 0;

        button.submit-contract-data {
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            flex: 1;
            align-items: center;
          }
        }
      }
    }
  }

  .data-institution-edition-open {
    .details-institution-container-open {
      .container-information {
        app-form {
          width: 100%;
          flex: 1 1;
          form {
            // display: grid;
            // grid-template-columns: 2fr 1fr;
            // max-width: unset;
            // width: inherit;
            display: flex;
            flex: 1;
            // align-items: baseline;
            flex-wrap: wrap;
            // max-width: 770px;
            gap: 22px;
            height: fit-content;

            app-form-control {
              flex: 1;

              &[id="name"] {
                // max-width: 481px;
                flex-grow: 2;
                min-width: 50%;
              }

              &[id="code"] {
                // max-width: 267px;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                & input[type="number"] {
                  -moz-appearance: textfield;
                }
              }

              mat-form-field {
                .mat-mdc-text-field-wrapper {
                  .mat-mdc-form-field-flex {
                    border: 1px solid #aeaeae;
                    border-radius: 2px;
                    color: #666666;
                  }
                }
              }
            }

            .institution-status {
              // width: 160px;
              flex: 1;
              position: relative;
              // top: 8px;
              // padding-bottom: 1.34375em;

              mat-slide-toggle.mat-checked {
                label.mat-slide-toggle-label {
                  height: 40px;

                  .mat-slide-toggle-bar {
                    width: 48px;
                    height: 24px;
                    border-radius: 20px;
                    background-color: #219653;

                    .mat-slide-toggle-thumb-container {
                      width: 16px;
                      height: 16px;
                      top: 4px;
                      transform: translate3d(27px, 0, 0);

                      .mat-slide-toggle-thumb {
                        width: 16px;
                        height: 16px;
                        background-color: #ffffff;
                      }
                    }
                  }
                }
              }

              mat-slide-toggle:not(.mat-checked) {
                label.mat-slide-toggle-label {
                  height: 40px;

                  .mat-slide-toggle-bar {
                    width: 48px;
                    height: 24px;
                    border-radius: 20px;

                    // background-color: #219653 !important;
                    .mat-slide-toggle-thumb-container {
                      width: 16px;
                      height: 16px;
                      top: 4px;
                      left: 4px;

                      .mat-slide-toggle-thumb {
                        width: 16px;
                        height: 16px;
                        background-color: #ffffff;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .alliance-section {
          mat-checkbox {
            label.mat-checkbox-layout {
              span.mat-checkbox-inner-container {
                height: 20px;
                width: 20px;
              }
            }
          }

          .warning-box-information {
            .icon-warning-container {
              width: 16px;

              span.material-icons {
                font-size: 16px;
              }
            }
          }
        }
      }

      .confirm-section {
        button {
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span.material-icons {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
