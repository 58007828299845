app-language-selector {
  mat-form-field.mat-form-field-appearance-fill {
    &.mat-focused {
      .icon-container {
        border: 1px solid var(--primary-color);
        border-radius: 4px;
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    div.mat-mdc-text-field-wrapper {
      padding: 0;
      background-color: transparent;
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }

      div.mat-mdc-form-field-flex {
        background-color: transparent;
        div.mat-mdc-form-field-infix {
          border-top: none;
          padding: 0;
          min-height: 24px;
          mat-select-trigger {
            mat-icon[svgicon="arrow-language"] {
              margin-left: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 16.11px;
                height: 8px;
              }
            }
            div .mat-mdc-select {
              div.mat-mdc-select-trigger {
                div.mat-mdc-select-arrow-wrapper {
                  display: none;
                  .mat-mdc-select-arrow {
                    transform: rotate(0deg);
                    color: var(
                      --language-selector-select-arrow-color,
                      rgba(0, 0, 0, 0.54)
                    );
                  }
                }
              }
            }
          }
        }
      }
      div.mdc-line-ripple {
        display: none;
      }
    }
    // &.mat-focused {
    //   svg {
    //     transform: rotate(180deg);
    //   }
    // }
  }
}

.cdk-overlay-pane .mat-select-panel-wrap div[role="listbox"].language-selector {
  filter: var(--language-selector-select-filter, none);
  box-shadow: var(
    --language-selector-select-box-shadow,
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%)
  );
}
