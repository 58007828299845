@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?ygslkp");
  src: url("../assets/fonts/icomoon.eot?ygslkp#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?ygslkp") format("truetype"),
    url("../assets/fonts/icomoon.woff?ygslkp") format("woff"),
    url("../assets/fonts/icomoon.svg?ygslkp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-Employees:before {
  content: "\e903";
}
.icon-A_Query:before {
  content: "\e901";
}
.icon-A_Operator:before {
  content: "\e902";
}
.icon-Password-Checked:before {
  content: "\e900";
}
