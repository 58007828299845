html .cdk-overlay-container {
  .cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
    background: #00000099;
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

button.mdc-switch {
  width: 48px;
  --mdc-switch-selected-track-color: #219653;

  .mdc-switch__track {
    --mdc-switch-track-shape: 12px;
    height: 24px;
  }
  &:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    background: #219653;
  }

  .mdc-switch__handle-track {
    .mdc-switch__handle {
      width: 16px;
      height: 16px;

      &::before,
      &::after {
        display: none;
      }

      .mdc-switch__icons {
        background-color: white;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  &.mdc-switch--unselected {
    .mdc-switch__handle-track {
      left: 5px;
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab {
          font-weight: bold;

          &.tab-button-new-importation {
            justify-content: flex-end;
            --mat-tab-header-inactive-ripple-color: transparent;
            opacity: 1;
            width: fit-content;
            max-width: fit-content;
            padding: 0;
            margin-left: 50px;

            .mdc-tab__content {
              pointer-events: all;
              .mdc-button {
                font-weight: bold;
                height: 48px;
                box-shadow: none;
                border-radius: 2px;
              }
            }
          }

          &.tab-button-invite {
            justify-content: flex-end;
            --mat-tab-header-inactive-ripple-color: transparent;
            opacity: 1;
            width: fit-content;
            max-width: fit-content;
            padding: 0;
            margin-left: 50px;

            .mdc-tab__content {
              pointer-events: all;
              .mdc-button {
                color: #ffffff;
                height: 48px;
                font-weight: bold;
                box-shadow: none;
                border-radius: 2px;
              }
            }
          }

          &.tab-button-invite {
            justify-content: flex-end;
            --mat-tab-header-inactive-ripple-color: transparent;

            a {
              color: #ffffff;
              font-weight: bold;
              box-shadow: none;
              border-radius: 2px;
            }
          }
          &.tab-button-new-importation {
            --mat-tab-header-inactive-ripple-color: transparent;
            max-width: fit-content;
            padding: 0;
            .mdc-button {
              border-radius: 2px;
              height: 48px;
            }
          }
        }
      }
    }
  }
}
.table-groups {
  .filter-container {
    margin: 16px 0;
    .mat-mdc-form-field {
      max-width: fit-content;
      height: fit-content;
      .mat-mdc-text-field-wrapper {
        border: var(--app-form-control-input-mat-form-field-flex-border);
        border-radius: 2px;
        background-color: white;

        .mat-mdc-form-field-focus-overlay {
          opacity: 0;
        }

        .mdc-line-ripple {
          display: none;
        }
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            input {
              font-size: 14px;
              color: #333333;
              font-weight: 700;
            }
            .mdc-floating-label {
              color: #48586c;
            }
          }
        }

        &[disabled="true"] {
          .mat-mdc-form-field-flex {
            border: unset;
            label {
              color: #aeaeae;
            }
            .mat-form-field-suffix {
              height: 100%;
              margin: auto;
            }
          }
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}

app-table-list-invited-alliance div,
app-table-list-invited-grafana div,
app-table-users div,
app-table-user-logs div,
app-table div {
  form.filter-container {
    margin: 16px 0;

    .mat-mdc-form-field {
      max-width: fit-content;
      height: fit-content;
      .mat-mdc-text-field-wrapper {
        border: var(--app-form-control-input-mat-form-field-flex-border);
        border-radius: 2px;
        background-color: white;

        .mat-mdc-form-field-focus-overlay {
          opacity: 0;
        }

        .mdc-line-ripple {
          display: none;
        }
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            input {
              font-size: 14px;
              color: #333333;
              font-weight: 700;
            }
            .mdc-floating-label {
              color: #48586c;
            }
          }
        }

        &[disabled="true"] {
          .mat-mdc-form-field-flex {
            border: unset;
            label {
              color: #aeaeae;
            }
            .mat-form-field-suffix {
              height: 100%;
              margin: auto;
            }
          }
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
  .parent table.mat-mdc-table[id="22"] {
    thead tr {
      background: white;
      th {
        border-bottom: none;
        &:first-of-type {
          padding-left: 32px;
        }
        &:last-of-type {
          padding-right: 32px;
        }
      }
    }

    tbody[role="rowgroup"] {
      background: white;
      tr {
        background: white;
        td {
          border-bottom: 3px solid #f6f6f6;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 40px;
          cursor: pointer;
          &:active {
            cursor: -webkit-grab;
            cursor: grab;
          }
          &:first-of-type {
            padding-left: 32px;
          }
          &:last-of-type {
            padding-right: 32px;
          }
        }
      }
    }
  }
}

.menu-box-fiserv-adm {
  .menu-line {
    mat-icon {
      min-width: 24px;
      &.mat-icon svg path {
        fill: rgba(0, 0, 0, 0.631372549);
      }
    }

    &:hover:not(.active) {
      mat-icon.mat-icon svg path {
        fill: #ffffff;
      }
    }
    &.active {
      mat-icon.mat-icon svg path {
        fill: var(--primary-color);
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane .menu-con-user-name-auth {
    button {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.631372549);
      mat-icon {
        &.mat-icon svg path {
          fill: rgba(0, 0, 0, 0.631372549);
        }
      }
    }
  }
  .cdk-overlay-connected-position-bounding-box
    .cdk-overlay-pane
    .mat-menu-panel
    .mat-menu-content {
    button {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.631372549);
    }
  }
}

.cdk-overlay-container .cdk-global-overlay-wrapper .interrupt-registration {
  mat-dialog-container {
    app-modal .content-modal .controls-modal {
      button {
        &:first-child {
          width: 162px !important;
        }

        span.mat-button-wrapper {
          font-weight: 700;
        }
      }
    }
  }
}

.cdk-global-overlay-wrapper
  .cdk-overlay-pane.modal-channel-application.mat-mdc-dialog-panel {
  max-height: 94vh;
  max-width: 89vw;

  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        background-color: whitesmoke;
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: var(--app-form-control-input-mat-form-field-flex-border);
      border-radius: 2px;
      background-color: white;

      .mat-mdc-select-value-text {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input,
          .input-email {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }
    }
  }

  mat-dialog-container {
    background-color: #f6f6f6 !important;
    .form-container {
      form {
        display: flex;
        margin-top: 20px;
        margin-left: 20px;
        gap: 20px;
        app-form-control {
          mat-form-field {
            &:nth-child(1) {
              .mat-mdc-text-field-wrapper {
                background-color: aqua !important;
              }
            }
          }
        }
      }
    }
  }
}

mat-stepper .mat-stepper-horizontal-line {
  margin: unset;
}

mat-slide-toggle.global-default.mat-checked {
  label.mat-slide-toggle-label {
    height: 40px;

    .mat-slide-toggle-bar {
      width: 48px;
      height: 24px;
      border-radius: 20px;
      background-color: #219653 !important;
      .mat-slide-toggle-thumb-container {
        width: 16px;
        height: 16px;
        top: 4px;
        transform: translate3d(27px, 0, 0);
        .mat-slide-toggle-thumb {
          width: 16px;
          height: 16px;
          background-color: #ffffff;
        }
      }
    }
  }
}
mat-slide-toggle.global-default:not(.mat-checked) {
  label.mat-slide-toggle-label {
    height: 40px;

    .mat-slide-toggle-bar {
      width: 48px;
      height: 24px;
      border-radius: 20px;
      .mat-slide-toggle-thumb-container {
        width: 16px;
        height: 16px;
        top: 4px;
        left: 4px;
        .mat-slide-toggle-thumb {
          width: 16px;
          height: 16px;
          background-color: #ffffff;
        }
      }
    }
  }
}

.cdk-overlay-pane.mat-datepicker-popup {
  .mat-calendar-body-cell::before,
  .mat-calendar-body-cell::after,
  .mat-calendar-body-cell-preview {
    top: 8%;
  }

  .mat-calendar-body-selected,
  .mat-calendar-body-cell-content {
    height: 70%;
  }
}
