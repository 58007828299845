@import "mixins";

app-user-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background-color: var(--app-user-password-bg-color);

  .panel#user-password {
    max-width: 366px;
    flex: 1;
    max-height: 609px;
    margin-top: 5.8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: var(--app-user-password-panel-bg-color);
    display: flex;
    flex-direction: column;

    button#buttonChangePassword {
      box-shadow: none;
      span.mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      height: 56px;
    }

    mat-card {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 360px) {
    .panel#user-password {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
      #first-access-button-box {
        margin: auto;
      }
    }
    .panel#insert-code.panel {
      max-width: 100vw;
      min-width: 288px;
      margin-bottom: unset;
      margin-top: unset;
      background-color: unset;
    }
  }

  @media screen and (max-height: 700px) {
    .panel#user-password {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
    .panel#insert-code.panel {
      margin-bottom: 5.8vh;
      min-height: 90vh;
      height: fit-content;
    }
  }
}
