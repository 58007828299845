app-modal-add-service-contract {
  app-form-control
    mat-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper {
    border: 1px solid #aeaeae;
    border-radius: 2px;
    .mat-mdc-form-field-subscript-wrapper {
      text-align: start;
    }
  }

  .url-container {
    display: flex;
    flex-direction: column;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    input.mat-mdc-input-element {
      // margin-left: 254px;
      margin-left: 254px;
      max-width: fit-content;
    }

    app-form-control
      mat-form-field.mat-form-field-appearance-fill
      .mat-mdc-text-field-wrapper {
      border: 1px solid #aeaeae;
      border-radius: 2px;
      .mat-mdc-form-field-infix {
        width: 400px;
      }
    }
  }

  .brand-name-container,
  .url-email-container {
    .mat-mdc-text-field-wrapper {
      border: 1px solid #aeaeae;
      border-radius: 2px;
      background-color: transparent;

      .mat-mdc-select-value-text {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
      .mdc-line-ripple {
        display: none;
      }
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            font-size: 14px;
            color: #333333;
            font-weight: 700;
          }
          .mdc-floating-label {
            color: #48586c;
          }
        }
      }
    }
  }
  // .brand-name-container,
  // .url-email-container {
  //   display: flex;
  //   flex: 1;
  //   max-width: 294px;
  //   mat-form-field.mat-form-field-appearance-fill {
  //     flex: 1;
  //     .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  //       background-color: var(
  //         --app-form-control-input-mat-form-field-background-color,
  //         white
  //       );
  //       box-sizing: var(
  //         --app-form-control-input-mat-form-field-flex-box-sizing
  //       );
  //       width: var(--app-form-control-input-mat-form-field-flex-width);
  //       flex: 1 1;
  //       display: flex;
  //       padding: 12px 16px 8px;
  //       height: 42px;
  //       align-items: center;
  //       border: 1px solid #aeaeae;
  //       border-radius: 2px;
  //       color: #666666;
  //       .mat-mdc-form-field-infix {
  //         padding: 0.25em 0 0.25em 0;

  //         input {
  //           font-size: 14px;
  //           line-height: 16px;
  //           letter-spacing: -0.02em;
  //           color: #333333;
  //           font-weight: 700;
  //           font-style: normal;
  //         }
  //         mat-label {
  //           color: #48586c;
  //           font-size: 12px;
  //           line-height: 14px;
  //         }
  //       }
  //     }
  //   }
  // }

  .data-service-contract-edition-open {
    .details-service-contract-container-open {
      .container-information {
        padding: 19px 0;
        app-form {
          form {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            align-items: center;
            gap: 22px;
            margin-top: 39px;
            app-form-control {
              flex: 1;
              &[id="name"] {
                max-width: 294px;
              }
              &[id="code"] {
                max-width: 172px;
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                & input[type="number"] {
                  -moz-appearance: textfield;
                }
              }
              &[id="url"] {
                min-width: 366px;
              }

              mat-form-field {
                .mat-mdc-text-field-wrapper {
                  .mat-mdc-form-field-flex {
                    border: 1px solid #aeaeae;
                    border-radius: 2px;
                    color: #666666;
                  }
                }
              }
            }
            .institution-status {
              max-width: 160px;
              padding-bottom: 1.34375em;
              mat-slide-toggle.mat-checked {
                label.mat-slide-toggle-label {
                  height: 40px;

                  .mat-slide-toggle-bar {
                    width: 48px;
                    height: 24px;
                    border-radius: 20px;
                    background-color: #219653;
                    .mat-slide-toggle-thumb-container {
                      width: 16px;
                      height: 16px;
                      top: 4px;
                      transform: translate3d(27px, 0, 0);
                      .mat-slide-toggle-thumb {
                        width: 16px;
                        height: 16px;
                        background-color: #ffffff;
                      }
                    }
                  }
                }
              }
              mat-slide-toggle:not(.mat-checked) {
                label.mat-slide-toggle-label {
                  height: 40px;

                  .mat-slide-toggle-bar {
                    width: 48px;
                    height: 24px;
                    border-radius: 20px;
                    // background-color: #219653 !important;
                    .mat-slide-toggle-thumb-container {
                      width: 16px;
                      height: 16px;
                      top: 4px;
                      left: 4px;
                      .mat-slide-toggle-thumb {
                        width: 16px;
                        height: 16px;
                        background-color: #ffffff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .alliance-section {
          mat-checkbox {
            label.mat-checkbox-layout {
              span.mat-checkbox-inner-container {
                height: 20px;
                width: 20px;
              }
            }
          }
          .warning-box-information {
            .icon-warning-container {
              width: 16px;

              span.material-icons {
                font-size: 16px;
              }
            }
          }
        }
      }
      .confirm-section {
        button {
          .mat-button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span.material-icons {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.cdk-overlay-container
  .cdk-global-overlay-wrapper
  .modal-add-service-contract
  mat-dialog-container {
  padding: 0;
}
