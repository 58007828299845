app-user-name-authenticate {
  div.main-toolbar {
    div.persona_info {
      button.user-channel {
        .mat-button-wrapper mat-icon.mat-icon {
          width: 22px;
          margin: 0 0 0 2px;
        }
      }
      button.user-name,
      button.user-channel {
        .mdc-button__label {
          margin-left: 5px;
        }
        .mat-mdc-menu-item-text {
          margin-left: 5px;
        }

        &:hover,
        &[aria-expanded="true"] {
          mat-icon.mat-icon svg path {
            fill: white;
          }
        }

        mat-icon.mat-icon {
          margin: 0 0 0 2px;
          width: 24px;
          svg path {
            fill: #000000a1;
          }
        }

        .mat-button-wrapper {
          display: flex;
          align-items: center;
          .mat-icon {
            display: flex;
          }
        }
      }
    }
  }
}
