app-form-token {
  // .ng-star-inserted + .ng-star-inserted {
  //   margin-left: 8px;
  // }

  div {
    .mdc-line-ripple {
      display: none;
    }

    span.ng-star-inserted {
      mat-form-field {
        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }

        .mat-mdc-form-field-input-control {
          font-weight: bold;
        }

        .mat-mdc-text-field-wrapper {
          width: 41px;
          padding: 1px;
          border: none;

          .mat-mdc-form-field-focus-overlay {
            background-color: #ffffff;
          }

          &.mdc-text-field-focused {
            background-color: #ffffff;
          }
          .mat-form-field-disabled .mat-mdc-form-field-flex {
            border-color: rgba(118, 118, 118, 0.3);
          }

          .mat-mdc-text-field-wrapper {
            padding-bottom: 0.5em;
          }

          .mat-mdc-form-field-flex {
            height: 56px;
            align-items: center;
            padding: 0;
            background: #ffffff;
            border: 1px solid #aeaeae;
            box-sizing: border-box;
            border-radius: 2px;
          }
        }
        &.ng-dirty .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
          border: 1px solid #fe5a59;
          box-sizing: border-box;
        }
      }

      + span.ng-star-inserted {
        margin-left: 8px;
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  &.invalidToken {
    div
      span.ng-star-inserted
      mat-form-field
      .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex {
      border: 1px solid #fe5a59;
      box-sizing: border-box;
    }
  }
}

.overlay-container
  .toast-container#toast-container.toast-top-center
  .ngx-toastr {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &.form-token-group-toast-error.toast-error {
    background-color: #fe5a59;
  }
  &.form-token-group-toast.resend.toast-success {
    background-color: #219653;
  }
}

@media (max-width: 556px) {
  .overlay-container
    .toast-container#toast-container.toast-top-center
    .ngx-toastr {
    height: 52px;
    align-items: flex-end;
    width: 100%;
    margin-top: 0px;
    &.form-token-group-toast.resend.toast-success,
    &.form-token-group-toast-error.toast-error {
      .toast-message.ng-star-inserted {
        height: 25px;
        align-items: center;
        display: flex;
      }
    }
  }
}
