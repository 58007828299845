.add-service-contract-panel.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-height: 94vh;
  max-width: 89vw;
  mat-dialog-container.mat-dialog-container {
    padding: 0;
  }
}
app-fiserv-date-services-contract {
  .data-service-contract {
    .details-contract-container {
      .add-sc-info {
        button {
          .mat-button-focus-overlay {
            background-color: transparent;
          }
        }
      }
    }
  }
}
