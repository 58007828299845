app-modal-channel-application {
  .content-modal .mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-label {
            color: #aeaeae;
            font-weight: 700;
            font-size: 16px;
            opacity: 0.9;
            &.mat-tab-label-active {
              opacity: 1;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .first-session-container {
    .card {
      .form {
        .input-session {
          .language-selector {
            .warning-container {
              .mat-icon {
                path {
                  fill: #666666;
                }
              }
            }
            mat-form-field.selectLanguageFormField {
              div.mat-mdc-text-field-wrapper {
                padding-bottom: 0em;
              }
            }
          }
        }

        .checkbox-container {
          .mat-checkbox .mat-checkbox-layout {
            font-size: 16px;
          }

          .warning-container {
            .mat-icon {
              path {
                fill: #666666;
              }
            }
          }
        }

        .information-container {
          .warning-container {
            .mat-icon {
              path {
                fill: #666666;
              }
            }
          }
        }
      }
    }
  }

  .auth-session-container {
    .mat-mdc-card {
      .mat-mdc-card-content {
        .form {
          .radio-buttons-div {
            .mat-radio-button.mat-accent.mat-radio-checked
              .mat-radio-inner-circle {
              background-color: black;
            }

            .mat-radio-button.mat-accent.mat-radio-checked
              .mat-radio-outer-circle {
              border-color: black;
            }
          }

          .url-session {
            .url-container {
              .mat-mdc-form-field-infix {
                display: flex;
                min-width: fit-content;
              }
              // .mat-form-field {
              //   flex: 1;
              // }
            }
          }
        }
      }
    }
  }
}
