app-invite-from-imported-table {
  .filters-box {
    button {
      &.upload-button {
        .mat-button-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          mat-icon {
            height: 16px;
            width: 16px;
            display: flex;
          }
        }

        &.mat-mdc-button-disabled .mat-button-wrapper {
          color: #dbdbdb;
          mat-icon svg path {
            fill: #dbdbdb;
          }
        }
      }
      &.advanced-filters-button {
        &.active-tab {
          mat-icon {
            color: #ff6600;
          }
        }

        .mat-button-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          mat-icon {
            width: 16px;
            height: 16px;
            line-height: 16px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .advanced-filters {
    app-form form .form-group {
      display: flex;
      gap: 22px;

      .input-box {
        width: 269px;
      }
    }
  }
}
