.auto-width-internal-select.mat-select-panel {
  max-width: unset !important;
}

app-internal-users-list {
  .download-file {
    mat-icon.mat-icon.download-icon {
      svg path {
        fill: white !important;
      }
    }
  }

  mat-form-field {
    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mat-mdc-form-field-infix {
      width: auto;
      min-width: 200px;
      mat-select[id="memberOf"] {
        width: -webkit-fill-available;
        width: -moz-available;
      }
    }
  }

  .page main.content mat-tab-group {
    mat-tab-header {
      margin-top: 30px;
      .mat-tab-label-container
        .mat-tab-list
        .mat-tab-labels
        .mat-tab-label-content {
        color: #ff6600;
      }
    }
  }
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    form {
      display: flex;
      gap: 22px;
      width: -webkit-fill-available;
      width: -moz-available;
      flex-wrap: wrap;
      .select-container {
        display: flex;
        border-radius: 2px;
        height: 60px;

        mat-label {
          color: #666666;
        }
        .mat-mdc-text-field-wrapper {
          padding-bottom: 0px;
          display: flex;

          .mat-mdc-form-field-flex {
            background-color: white;
          }
        }
      }

      .mat-mdc-form-field.form-field-group {
        max-height: fit-content;
        --mat-form-field-hover-state-layer-opacity: 0;
        .mat-mdc-text-field-wrapper {
          background-color: white;
        }
      }

      input {
        flex: 1 1;
        padding: 18px 16px;
        border-radius: 2px;
        border-color: transparent;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        outline-color: #ff6600;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-weight: 400;
          color: #666666;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #666666;
          font-weight: 400;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #666666;
          font-weight: 400;
        }
        &:focus-visible {
          outline-color: #ff6600;
        }
        &[id="name"] {
          max-width: 261px;
        }
        &[id="functional"] {
          max-width: 120px;
        }
        &[id="email"] {
          min-width: 250px;
        }
      }
      button.search-button.mat-flat-button.mat-primary {
        color: #666666;
        &:not([disabled="true"]) {
          color: #ffffff;
        }
        flex: 1 1;
        height: 60px;
        max-width: 172px;
        min-width: fit-content;
        justify-content: center;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 678px) {
    .title {
      h1 {
        text-align: center;
        display: none;
      }
    }
    .filter-container {
      form {
        display: grid;

        .select-container {
          min-width: -webkit-fill-available;
          min-width: -moz-available;
          .mat-mdc-text-field-wrapper {
            min-width: -webkit-fill-available;
            min-width: -moz-available;
            .mat-mdc-form-field-flex {
              min-width: -webkit-fill-available;
              min-width: -moz-available;
              width: auto;
            }
          }
        }
        input {
          &:first-child {
            max-width: unset;
            min-width: -webkit-fill-available;
            min-width: -moz-available;
          }
          &:nth-child(2) {
            max-width: unset;
            min-width: -webkit-fill-available;
            min-width: -moz-available;
          }
          &:nth-child(3) {
            max-width: unset;
            min-width: -webkit-fill-available;
            min-width: -moz-available;
          }
        }
        button.search-button.mat-flat-button.mat-primary {
          flex: 1 1;
          height: 60px;
          max-width: unset;
          justify-content: center;
          min-width: -webkit-fill-available;
          min-width: -moz-available;
        }
      }
    }
  }
}
