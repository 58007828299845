app-pagination {
    .pagination {

        .pagination-controls {

          
            svg {
                height: 60%;
                margin-bottom: 4px;
            }
        
        }
    
    }
    
}